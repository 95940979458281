const test = {
  pages: [
    {
      id: "page-e821354b-e7c6-429f-a7f9-3e6cbd7ee270",
      styles: {
        marginLeft: "5%",
        marginRight: "5%",
      },
      content: [
        {
          id: "shortcode-e84f7d4f-965f-4e03-b55e-1004667ea016",
          type: "shortcode",
          style: {
            color: "#000000",
            fontSize: 24,
          },
          value: "$DEPARTMENT_EMAIL$",
        },
        {
          id: "text-693bc0ff-0102-4b4f-b473-71cb71115e99",
          type: "text",
          style: {
            color: "#FF0000",
            fontSize: 20,
          },
          value: "test",
        },
        {
          id: "column-e821354b-e7c6-429f-a7f9-3e6cbd7ee270",
          style: {
            color: "#0000000",
            fontSize: 12,
          },
          type: "column",
          value: [
              {
                  width: 100,
                  style: {
                    color: "#0000000",
                    fontSize: 12,
                  },
                  value: [
                    {
                        id: "shortcode-zer21654ert",
                        type: "shortcode",
                        style: {
                          color: "#0000000",
                          fontSize: 12,
                        },
                        value: "$DEPARTMENT_EMAIL$",
                      },
                  ]
              }
          ],
        },
      ],
    },
  ],
};

export default test