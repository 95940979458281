import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
  LinearProgress,
  FilledInput,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Chip,
  Select,
  Divider,
  MenuList
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import SaveIcon from "@mui/icons-material/Save";
import FolderIcon from "@mui/icons-material/Folder";
import Check from "@mui/icons-material/Check";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FaUndo, FaRedo } from "react-icons/fa";
import TLlogo from "../../img/TL_logo.png";
import TLlogoFilled from "../../img/TL_logo_blue.png";
import Logo from "../../img/logo.png";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../contexts/ApiContext";
import LoginSignUp from "../LoginSignUp";
import Users from "../Admin/Users";


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0].toUpperCase()}`,
  };
}

function TopBar(props) {
  const [name, setName] = useState("New design");
  const [showLogin, setShowLogin] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [APIEl, setAPIEl] = React.useState(null);
  const [document, setDocument] = React.useState("quotation");
  const [previewdocuments, setPreviewDocuments] = React.useState([]);
  const open = Boolean(anchorEl);
  const openAPI = Boolean(APIEl);
  const { departments, quotations, invoices, refreshData, token, previewEnabled, setPreviewEnabled } = useApi()

  const [preview, setPreview] = useState("")


  const { currentUser, logout, tokens } = useAuth();


  function getExpirationDate() {
    const endDate = new Date(tokens)
    const today = new Date()
    const differenceInTime = endDate.getTime() - today.getTime()
    const differenceInDays = differenceInTime / (1000 * 3600 * 24)
    return Math.ceil(differenceInDays)
  }


  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  function changeName() {
    props.changeName(name);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  function getTrialDaysRemaining() {
  }




useEffect(() => {
  switch (document) {
    case "quotation":
      setPreviewDocuments(quotations)
      if (previewdocuments.length > 0) {
        setPreview(previewdocuments[0].id)}
      break;
    case "invoice":
      setPreviewDocuments(invoices)
      if (previewdocuments.length > 0) {
        setPreview(previewdocuments[0].id)}
      break;
    default: setPreviewDocuments(quotations)
  }

}, [document])


  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      elevation={2}
    >
      < Users show={showUsers} handleClose={() => setShowUsers(false)}/>
      <Toolbar variant="dense">
        <IconButton sx={{ marginLeft: 1.5 }} onClick={() => setShowUsers(true)}>
          <img src={Logo} style={{width: "30px"}} alt=""/>
        </IconButton>
        <Divider orientation="vertical" flexItem sx={{ marginRight: 2, marginLeft: 2 }} />
        <FilledInput
          disableUnderline
          hiddenLabel
          label=""
          margin="dense"
          variant="outlined"
          value={name}
          required
          color="secondary"
          inputProps={{ color: "#FFFFFF" }}
          sx={{ width: `${name.length * 11 + 40}px`, marginRight: 2 }}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => changeName()}
        />
        <Divider orientation="vertical" flexItem />

        <Select
          id="select-document"
          disableUnderline
          value={document}
          hiddenLabel
          label=""
          margin="dense"
          variant="filled"
          color="secondary"
          sx={{ marginRight: 1, marginLeft: 1 }}
          inputProps={{
            color: "#FFFFFF",
            sx: { fontWeight: "400", fontSize: "1rem" },
          }}
          onChange={(e) => setDocument(e.target.value)}
        >
          <MenuItem value="quotation">Quotation</MenuItem>
          <MenuItem value="invoice">Invoice</MenuItem>
          <MenuItem value="timetracking">Time tracking report</MenuItem>
          <MenuItem value="order">Order form</MenuItem>
          <MenuItem value="delivery">Delivery note</MenuItem>
          <MenuItem value="workorder">Work order</MenuItem>
        </Select>
        <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />

        <Tooltip arrow title="Library" placement="bottom">
          <IconButton
            aria-label="my-templates"
            onClick={() => props.showTemplates()}
          >
            <FolderIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download docx" placement="bottom">
          <IconButton aria-label="download" onClick={() => props.download()}>
            <SaveIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Tooltip>
        <IconButton
          aria-label="undo"
          onClick={() => props.undo()}
          disabled={!props.canUndo}
        >
          <FaUndo fill={props.canUndo ? "white" : "#6ec6ff"} size=".8rem" />
        </IconButton>
        <IconButton
          aria-label="redo"
          onClick={() => props.redo()}
          disabled={!props.canRedo}
        >
          <FaRedo fill={props.canRedo ? "white" : "#6ec6ff"} size=".8rem" />
        </IconButton>
        <Box component="div" sx={{ flexGrow: 1 }} />
          {currentUser && (!isNaN(getExpirationDate())) && (
            <Chip mr={3} sx={{color: "primary.main", backgroundColor: "#FFFFFF", marginRight: "1rem"}} label={`${getExpirationDate()} days left on trial`} />
          )
          }
        {currentUser && token == undefined && (
          <Tooltip arrow title="Connect to Teamleader" placement="bottom">
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              endIcon={<img style={{ width: "30px" }} src={TLlogo} alt="" />}
              onClick={() =>
                (window.location.href = `https://focus.teamleader.eu/oauth2/authorize?client_id=10f1f90f99b0ea130dd822c906db33a7&response_type=code&state=${currentUser.uid}&redirect_uri=http:%2F%2Flocalhost:5001%2Ftlgen-7a679%2Fus-central1%2FconnectTeamleader`)
              }
            >
              Connect
            </Button>
          </Tooltip>
        )}

        {currentUser && token !== undefined && (
          <>
            {previewEnabled && <Select
              id="select-document"
              disableUnderline
              value={preview}
              placeholder="Choose a document"
              hiddenLabel
              label=""
              margin="dense"
              variant="filled"
              color="secondary"
              sx={{ marginRight: 1, marginLeft: 1 }}
              inputProps={{
                color: "#FFFFFF",
                sx: { fontWeight: "400", fontSize: "1rem" },
              }}
              onChange={(e) => setPreview(e.target.value)}
            >
              {previewdocuments.map((doc, i) => {
                return (
                  <MenuItem value={doc.id} key={i}>
                    {doc.title}
                  </MenuItem>
                );
              })} 
            </Select>}
            <Tooltip arrow title="Connection settings" placement="bottom">
              <Button
                variant="contained"
                size="small"
                sx={{
                  color: "#2196F3",
                  backgroundColor: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#2196F3",
                    color: "#FFFFFF",
                    outline: "1px solid #FFFFFF",
                    
                  },
                }}
                disableElevation
                endIcon={
                  <img style={{ width: "30px" }} src={TLlogoFilled} alt="" />
                }
                onClick={(e) => {
                  setAPIEl(e.currentTarget);
                }}
              >
                Connected
              </Button>
            </Tooltip>
          </>
        )}
        {!currentUser && (
          <Button
            color="inherit"
            endIcon={
              <Avatar sx={{ width: 25, height: 25 }} src="/broken-image.jpg" />
            }
            onClick={() => setShowLogin((old) => !old)}
          >
            Sign in
          </Button>
        )}

        {currentUser && (
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ width: 30, height: 30 }}
              {...stringAvatar(currentUser.email)}
              src="/broken-image.jpg"
            />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuList dense>
            <MenuItem onClick={() => logout()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>

        {/* API MENU */}
        <Menu
          anchorEl={APIEl}
          id="account-menu"
          open={openAPI}
          onClose={handleClose}
          onClick={() => setAPIEl(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              minWidth: 180,
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },

            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuList dense>
            <MenuItem onClick={() => setPreviewEnabled((old) => !old)}>
              <ListItemIcon>
                {previewEnabled && <Check />}
              </ListItemIcon>
              Preview mode
            </MenuItem>

            {departments.length > 0 && <Box mb={1}>
              <Divider />

<Typography ml={2} mt={1} mb={1} variant="body2" display="block" color="primary">
              Department
            </Typography>
              {departments.map((item, i) => {
              return (
                <MenuItem key={i}>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  {item.name}
                </MenuItem>
              );
            })}</Box>}
            <Divider />
            <MenuItem onClick={() => refreshData()}>
              <ListItemIcon>
                <RefreshIcon />
              </ListItemIcon>
              Refresh data
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <CloudOffIcon />
              </ListItemIcon>
              Disconnect
            </MenuItem>
          </MenuList>
        </Menu>
      </Toolbar>
      {showLogin && <LoginSignUp />}
      {props.loading && (
        <LinearProgress
          color="secondary"
          show={showLogin}
        />
      )}
    </AppBar>
  );
}

export default TopBar;
