import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Card from "@mui/material/Card";
import Box from '@mui/material/Box'
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"



export default function ActionCardSkeleton() {
  return (
    <Card sx={{ width: "300px", height: "540px" }}>
        <Box p={4} sx={{ flexGrow: 1, height: "345px", borderBottom: "2px solid #F4F4F4" }}>
        <Grid container spacing={6} mb={4}>
            <Grid item>
                <Skeleton variant="circular" width={60} height={60} />
            </Grid>
                <Grid item>
                    <Skeleton width={70} />
                    <Skeleton width={90} />
                    <Skeleton width={70} />
                </Grid>
        </Grid>
        <Skeleton width={240} />
        <Skeleton width={240} />
        <Skeleton width={180} />
        <Box mt={2} >
            <Skeleton width={237}/>
        </Box>

        <Grid container spacing={1}>
                <Grid item>
                    <Skeleton variant="rectangular" width={100} height={70} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rectangular" width={20} height={70} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rectangular" width={20} height={70} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rectangular" width={20} height={70} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rectangular" width={42} height={70} />
                </Grid>
                
        </Grid>
        <Box mt={3}>
            <Skeleton width={70}/>
            <Skeleton variant="rectangular" width={50} height={30}/>
        </Box>

        </Box>

        <Typography pl={2} pr={2} pt={2} width={260}  variant="h3"><Skeleton /></Typography>
        <Typography pl={2} pr={2} width={150} variant="body2"><Skeleton /></Typography>
</Card>
  )
}
