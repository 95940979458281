import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

//import { Routes, Route, Link } from "react-router-dom";
import Editor from "./components/Editor";
import Generator from "./components/Generator";
import { Helmet } from "react-helmet";
import {ThemeProvider } from '@mui/material/styles';
import theme from "./styles/theme";
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from "./contexts/AuthContext";
import { ApiProvider } from './contexts/ApiContext'
import { StyleProvider } from './contexts/StyleContext'
/* import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css'; */
import "@fontsource/poppins"
import "@fontsource/domine"
import './styles/fonts.css'
import HandleInvite from './components/handleInvite'



export default function App() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
      <Router>


      <AuthProvider>
        <ApiProvider>
        <StyleProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
              <Routes>
                <Route path="/generator" element={<Generator />} />
                <Route path="/" element={<Editor />} />
                <Route
                 path="/account/:actionCode"
                 exact
                 element={<HandleInvite />}
               />
                <Route
                 path="/invite/:invite"
                 exact
                 element={<HandleInvite />}
               />
              </Routes>
            </SnackbarProvider>
          </ThemeProvider>
          </StyleProvider>

        </ApiProvider>
      </AuthProvider>
      </Router>

    </>
  );
}
