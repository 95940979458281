import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Box,
  Stack,
  Modal,
  Fade,
  Button,
  Typography,
  Tabs,
  Tab,
  TextField,
  Alert,
  CircularProgress
} from "@mui/material";
import CiceroLogo from "../img/CiceroLogo.png";
import { functions, auth, db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  maxHeight: 650,
  bgcolor: "background.paper",
  boxShadow: 5,
  borderRadius: "15px",
  padding: 5,
};

export default function HandleInvite() {
  const [email, setEmail] = useState("");
  const [trialToken, setTrialToken] = useState("");
  const [error, setError] = useState("");
  const [mode, setMode] = useState("");
  const [URI, setURI] = useState("");
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const { currentUser, setFirstnameDisplay, login  } = useAuth()

  function getQueryVariable(variable) {
    //get params
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  // const oobCode = getQueryVariable("oobCode"); // get reset code
  // const resetPasswordUrl = getQueryVariable("continueUrl"); // get reset code


  useEffect(() => {
    setTrialToken(getQueryVariable("token")); // get reset code
    setEmail(getQueryVariable("email")); // get reset code
  
  }, [])
  
/*   function checkOobCode() {
    auth
      .checkActionCode(oobCode)
      .then((result) => {
        if (result.operation === "VERIFY_EMAIL") {
          setURI(decodeURIComponent(resetPasswordUrl).substring(16));
          setEmail(result.data.email);
        }
        if (result.operation === "PASSWORD_RESET") {
          setEmail(result.data.email);
        }
        if (result.operation === "EMAIL_SIGNIN") {
            console.log(decodeURIComponent(resetPasswordUrl).substring(16))

            if (auth.isSignInWithEmailLink(window.location.href)) {
                const email = getQueryVariable("email"); // get reset code
                setEmail(email)
                 auth.signInWithEmailLink(email, window.location.href).then((user) => {
                    setMode("resetPassword")
                })

            }


        }
        console.log(result);
        // Display a "new password" form with the user's email address
      })
      .catch((err) => {
        if (err.code === "auth/invalid-action-code") {
          setError("Verification link expired");
        }
      });
  } */


/*   async function handlePasswordReset() {
      await auth.currentUser.updatePassword(password).then(async () => {
            await db.collection('users').doc(currentUser.uid).update({
                firstName: firstName,
                lastName: lastName,
                active: true,
            })


      }).then(() => {
        setFirstnameDisplay(firstName)
        navigate('/')
      } ).catch(err => console.log(err))
      setDisabled(false)

  } */

  async function handleSignup() {
    setDisabled(true)
    setLoading(true)
    const handleInvite = functions.httpsCallable('handleInvite')
    await handleInvite({
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password, 
      inviteToken: trialToken
    }).then(async (data) => {
      console.log(data)
      await login(email, password)
      navigate('/')
    }).catch((err) => console.log(err))
    setLoading(false)
    setDisabled(false)
  }

  useEffect(() => {
    if ( password !== "") {
      if (password === passwordRepeat) {
      setDisabled(false)
      } else {setDisabled(true)}
    } else {setDisabled(true)}

  }, [password, passwordRepeat, email])

/*   async function verifyAccount() {
      auth.
    navigate(URI);
    await auth.applyActionCode(oobCode);
    setMode(getQueryVariable("mode"));
    checkOobCode();
  }

  useEffect(() => {
    setMode(getQueryVariable("mode")); // get reset code
    checkOobCode();
  }, []); */

  return (
    <Box sx={{ backgroundColor: "#F7F7F7", width: "100vw", height: "100vh" }}>
      <Fade in>
        <Box sx={style}>
          <Box pb={6}>
            <img src={CiceroLogo} alt="" width={150}></img>
          </Box>
          {error === "" && (
            <Fade in={error === ""}>
              <Stack spacing={3}>
                <Typography>
                  Create a password for <b>{email}</b>
                </Typography>
                <TextField
                id="firstname"
                label="First name"
                variant="outlined"
                type="text"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                id="lastname"
                label="Last name"
                variant="outlined"
                type="text"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
                <TextField
                id="password-signups"
                label="Password"
                variant="outlined"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                id="password-repeat"
                label="Repeat password"
                variant="outlined"
                type="password"
                required
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
              />
                <Button onClick={() => handleSignup()} variant="contained" size="large" disabled={disabled}>
                  Login
                </Button>
                {loading && <CircularProgress

color="secondary"
sx={{alignSelf: "center"}}
/>}
              </Stack>
            </Fade>
          )}

          <Fade in={error !== ""}>
            <Stack spacing={3}>
              <Alert mt={6} severity="error">
                {error}
              </Alert>
              <Button onClick={() => navigate("/")} variant="contained" size="large">
                Go home
              </Button>
            </Stack>
          </Fade>

        </Box>
      </Fade>
    </Box>
  );
}
