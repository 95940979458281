import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export function Drawer(props) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    color: isOver ? 'green' : "white",
    backgroundColor: isOver? 'coral': 'var(--edit-color)',
    border: "1px solid black",
    minWidth: "200px",
    maxWidth: "200px",
    height: "100%",
    fontWeight: 'bold'
  };
  
  
  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
}