import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Chip,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  IconButton,
  Link,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Skeleton,
  Fade
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "swiper/css";
import "swiper/css/navigation";
import ActionCard from "../ui/ActionCard";
import ActionCardSkeleton from "../ui/ActionCardSkeleton";
import { useSnackbar } from 'notistack';
import { storage, functions, db } from "../../firebase";


export default function UserInfo(props) {
    const [userinfo, setUserinfo] = useState({})
    const [myDesigns, setMyDesigns] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openOptions = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();

    const myDesignsSwiperRef = useRef(null);


    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = "#";
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

      
      function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
            height: "150px",
            width: "150px",
            fontSize: '4rem'
          },
          children: `${name.split(" ")[0][0].toUpperCase()}`,
        };
      }
      
      function pictureAvatar(name) {
        return {
          sx: {
            backgroundImage: `url(${name})`,
            backgroundSize: 'cover',
            height: "150px",
            width: "150px",
          },
          children: ``,
      
      };
      }

      async function makeAdmin() {
        const admin = functions.httpsCallable("makeAdmin");
        console.log(props.id)
        await admin({
          uid: props.id,
        }).then((data) => {
          if (data.data.errorInfo !== undefined) {
            enqueueSnackbar(data.data.errorInfo.message, { variant: "error" })
          } else {
            enqueueSnackbar(data.data.message, { variant: "success" })      
          }
            getData()
        }).catch(err => console.log(err));
      }


      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleOptionsClose = () => {
        setAnchorEl(null);
      };

    async function getData() {
        setLoading(true)

        await db.collection('users').doc(props.id).get().then((user) => {
            setUserinfo(user.data())
        })

        setMyDesigns([]);
        await db.collection("users")
          .doc(props.id)
          .collection("designs")
          .get()
          .then((templates) => {
            templates.forEach(async (doc) => {
              await storage
                .ref(`users/${props.id}/designs/${doc.id}.jpg`)
                .getDownloadURL()
                .then((result) => {
                  setMyDesigns((old) => [
                    ...old,
                    {
                      id: doc.id,
                      name: doc.data().title,
                      lastUpdate: doc
                        .data()
                        .lastUpdate.toDate()
                        .toLocaleString("nl-BE", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }),
                      image: result,
                    },
                  ]);
                });
            });
            setLoading(false)

          });
      }
    

    useEffect(() => {
      getData()
    }, [])
    
  return (
      <Fade in>
    <Container maxWidth="lg" sx={{ position: "relative" }}>
      <IconButton
        sx={{ position: "absolute", left: "-2rem", top: "4rem" }}
        onClick={() => {
          props.setUserDetail(false)
          props.refresh()  
        }}
      >
        <ChevronLeftIcon fontSize="large" color="primary" />
      </IconButton>
      <Grid container spacing={10}>
        <Grid item xs={5}>
          <Card sx={{ position: "relative" }}>
          <>
            <IconButton onClick={handleClick} sx={{position: "absolute", right: 0}}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openOptions}
              onClose={handleOptionsClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={() => makeAdmin()}>Make admin</MenuItem>
              <MenuItem sx={{ color: "red" }}>Suspend</MenuItem>
            </Menu>
          </>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "2rem",
              }}
            >
              {loading ? <Skeleton variant="circular" width={150} height={150} /> : <Avatar
                {...(userinfo.avatarUrl === "" || userinfo.avatarUrl === undefined
                  ? { ...stringAvatar(userinfo.email) }
                  : { ...pictureAvatar(userinfo.avatarUrl) })}
              />}
              <Typography
                id="username"
                variant="h5"
                component="h2"
                pt={3}
                pb={1}
              >
                {loading ? <Skeleton width={250} /> : (userinfo.firstName + " " + userinfo.lastName)}
              </Typography>
              <Link mb={4} href={`mailto:${userinfo.email}`}>
              {loading ? <Skeleton width={150} /> : userinfo.email}
              </Link>
              <Box mb={6}>
              {loading ? <Skeleton width={50} /> : <Chip
                  size="small"
                  label={userinfo.subscription}
                  variant="outlined"
                  color="primary"
                />}
              </Box>

              <Table aria-label="user-card">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton width={150} /> : "Signed up"}</TableCell>
                    <TableCell align="right">
                      {!loading && userinfo.accountCreated !== undefined ?
                        userinfo.accountCreated
                          .toDate()
                          .toLocaleString("nl-BE", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }) :  <Skeleton width={150} />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ color: "darkgray" }}>
                    {loading ? <Skeleton width={150} /> : "Last renewal"}
                    </TableCell>
                    <TableCell align="right">
                    {!loading && userinfo.accountCreated !== undefined ?
                        userinfo.accountCreated
                          .toDate()
                          .toLocaleString("nl-BE", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }) :  <Skeleton width={150} />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton width={150} /> : "Status"}</TableCell>
                    <TableCell align="right">
                    {loading ? <Skeleton width={150} /> : <Chip
                        size="small"
                        label={userinfo.active ? "Active" : "Inactive"}
                        variant="outlined"
                        color={userinfo.active ? "success" : "error"}
                      />}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Typography id="username" variant="h5" component="h2" pt={1} pb={3}>
            User Properties
          </Typography>
          <Table aria-label="user-card">
            <TableBody>
                
              <TableRow>
                <TableCell sx={{ color: "darkgray" }}> {loading ? <Skeleton  /> : 'Identifier'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton/> : props.id}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton  /> : 'First name'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton  /> : userinfo.firstName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton  /> : 'Last name'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton  /> : userinfo.lastName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton  /> : 'Email'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton  /> : <Link href={`mailto:${userinfo.email}`}>{userinfo.email}</Link> }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton  /> : 'Avatar url'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton  /> : <Link href={userinfo.avatarUrl}>{userinfo.avatarUrl}</Link>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton  /> : 'Subscription'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton  /> : userinfo.subscription}
                </TableCell>
              </TableRow>
              {userinfo.subscription === "trial" && <TableRow>
                <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton  /> : 'Trial expires on'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton  /> : userinfo.trialExpiresOn.toDate().toLocaleString("nl-BE", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                </TableCell>
              </TableRow>}
              <TableRow>
                <TableCell sx={{ color: "darkgray" }}>{loading ? <Skeleton  /> : 'Teamleader'}</TableCell>
                <TableCell align="right">
                {loading ? <Skeleton  /> : <Chip
                  size="small"
                  label={userinfo.accessToken ? "Connected" : 'Not connected'}
                  variant="outlined"
                  color={userinfo.accessToken ? "success" : 'error'}
                />}
                </TableCell>
              </TableRow>

                  <TableRow>
                    <TableCell sx={{ color: "darkgray" }}>
                    {loading ? <Skeleton  /> : 'Last seen'}
                    </TableCell>
                    <TableCell align="right">
                      {!loading && userinfo.accountCreated !== undefined ?
                        userinfo.accountCreated
                        .toDate().toLocaleString('nl-BE', {day: 'numeric', month: "short", year: "numeric", hour: "numeric", minute: "numeric"}) : <Skeleton />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ color: "darkgray" }}>
                    {loading ? <Skeleton  /> : 'Designs'}
                    </TableCell>
                    <TableCell align="right">
                      {loading ? <Skeleton /> : myDesigns.length}
                    </TableCell>
                  </TableRow>
            </TableBody>
          </Table>
          {myDesigns.length > 0 && <><Typography id="username" variant="h5" component="h2" pt={6} pb={3}>
            Designs
          </Typography>
          <Box
                  sx={{
                    position: "relative",
                  }}
                >

                  <Swiper
                    style={{ padding: "2px 2px" }}
                    spaceBetween={10}
                    slidesPerView={2}
                    modules={[Navigation]}
                    ref={myDesignsSwiperRef}
                    observeParents
                    observer
                  >
                    {loading ? (
                      <>
                        <SwiperSlide key={1}>
                          <ActionCardSkeleton />
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                          <ActionCardSkeleton />
                        </SwiperSlide>
                      </>
                    ) : (
                      myDesigns.map((template, i) => {
                        return (
                          <SwiperSlide key={i + 1}>
                            <ActionCard
                              key={i}
                              callback={(e) => props.callback(e)}
                              name={template.name}
                              id={template.id}
                              description={`Last update: ${template.lastUpdate}`}
                              image={template.image}
                            />
                          </SwiperSlide>
                        );
                      })
                    )}
                  </Swiper>
                  {myDesigns.length > 2 && (
                    <>
                      <div
                        onClick={() =>
                          myDesignsSwiperRef.current.swiper.slideNext()
                        }
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "-2rem",
                          cursor: "pointer",
                          zIndex: 9999,
                        }}
                      >
                        <NavigateNextIcon fontSize="large" color="primary" />
                      </div>
                      <div
                        onClick={() =>
                          myDesignsSwiperRef.current.swiper.slidePrev()
                        }
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "-3rem",
                          cursor: "pointer",
                          zIndex: 9999,
                        }}
                      >
                        <NavigateBeforeIcon fontSize="large" color="primary" />
                      </div>
                    </>
                  )}
                </Box>
                </>}
        </Grid>
      </Grid>
    </Container>
    </Fade>
  );
}
