import React, { useState, useEffect, useRef } from "react";
import { Droppable } from "../Droppable";

import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { MdDragIndicator, MdClose } from "react-icons/md";
import { Shortcode } from "./Shortcode";
import Text from "./Text";
import { Resizable } from "re-resizable";
import { CSS } from "@dnd-kit/utilities";
import Image from "./Image";

export function SortableItemDragHandle(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const [cursor, setCursor] = useState("move");
  const [hovered, setHovered] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
    marginBottom: "5px",
  };

  let selected;

  if (props.selected === props.id) {
    selected = true;
  }

  const dragHandle = (
    <div
      {...listeners}
      {...attributes}
      style={{ cursor: cursor }}
      onMouseDown={() => setCursor("grabbing")}
      onMouseUpCapture={() => setCursor("move")}
    >
      <MdDragIndicator />
    </div>
  );

  let moduleStyle;
  if (selected) {
    moduleStyle = "selected";
  }

  if (!selected && hovered) {
    moduleStyle = "hovermodule";
  }

  return (
    <div
      ref={setNodeRef}
      id={props.id}
      className={moduleStyle}
      style={style}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onClick={() => props.click(props.id)}
    >
      {props.children}
      {selected && (
        <div className="remove">
          {dragHandle}

          <div
            onClick={() => {
              props.removeItem(props.parent, props.id);
            }}
          >
            <MdClose />
          </div>
        </div>
      )}
    </div>
  );
}

function Column(props) {
  const [columns, setColumns] = useState([]);
  const [column, setColumn] = useState([]);
  const [moduleWidth, setModuleWidth] = useState(500);
  const [moduleHeight, setModuleHeight] = useState();
  const [mouseOver, setMouseOver] = useState();
  const [selected, setSelected] = useState();
  const [values, setValues] = useState({ content: { 0: ["test"] } });
  const wrapperRef = useRef(null);


  useEffect(() => {
    const breedte = document.getElementById(props.id).offsetWidth;
    const hoogte = document.getElementById(props.id).offsetHeight
    setModuleWidth(breedte);
    setModuleHeight(hoogte);
    setColumns([{ width: breedte }]);
  }, []);

    // handle click outside
    useEffect(() => {
      document.addEventListener(
        "mousedown",
        function (event) {
          if (event.detail === 1) {
            handleClickOutside(event);
          }
        },
        false
      );
      return () => {
        document.removeEventListener(
          "mousedown",
          function (event) {
            if (event.detail === 1) {
              handleClickOutside(event);
            }
          },
          false
        );
      };
    }, []);
  
    // handle click outside selected
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSelected("");
      }
    };  


  useEffect(() => {

    return     props.innerSelected(`${column}-${selected}`)
  }, [selected])
  
  const active = props.active;



  function addColumn() {
    //add new column
    if (props.value.length <= 4) {
      props.addColumn();
    }
  }

  function removeColumn(i) {
    props.removeColumn(i);
  }

  function handleResize(i, width, direction) {
    const newWidth = (width / moduleWidth) * 100;
    props.handleColumnResize(i, Math.ceil(newWidth));
  }

  return (
    <div id={props.id} className="column" ref={wrapperRef}>
      {props.value.map((item, i) => {
        return (
          <Resizable
            size={{
              width: (moduleWidth / 100) * item.width,
              height: "100%",
            }}
            onResizeStop={(e, direction, ref, d) => {
              handleResize(i, d.width, direction);
            }}
            enable={{
              top: false,
              right:
                props.value.length > 1 && i !== props.value.length - 1
                  ? true
                  : false,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            onMouseEnter={() => setMouseOver(i)}
            onMouseLeave={() => setMouseOver(undefined)}
            style={{
              borderLeft: props.active && "1px dashed var(--edit-color)",
              backgroundColor: "none",
            }}
            key={i}
          >
            <Droppable id={`${i}-${props.id}`}>
            {props.value[i].value.length === 0 && <p className="placeholder">Sleep een element...</p>}

              <SortableContext
                id={`${i}-${props.id}`}
                items={props.value[i].value}
              >
                {props.value[i].value.map((item) => {
                  switch (item.type) {
                    case "text":
                      return (
                        <SortableItemDragHandle
                          parent={`${i}-${props.id}`}
                          key={item.id}
                          id={item.id}
                          click={() => {
                            setSelected(item.id)
                            setColumn(i)
                          }}
                          selected={selected}
                          removeItem={props.removeColumnItem}
                        >
                          <Text
                            id={item.id}
                            value={item.value}
                            styles={item.style}
                            onChange={(value) =>
                              props.handleColumnComponentChange(
                                value,
                                props.id,
                                i,
                                item.id
                              )
                            }
                          />
                        </SortableItemDragHandle>
                      );
                      break;
                    case "shortcode":
                      return (
                        <SortableItemDragHandle
                          parent={`${i}-${props.id}`}
                          key={item.id}
                          id={item.id}
                          click={() => {
                            setSelected(item.id)
                            setColumn(i)

                          }}                          selected={selected}
                          removeItem={props.removeColumnItem}
                        >
                          <Shortcode
                            id={item.id}
                            active={selected === item.id && true}
                            defaultValue={item.value}
                            styles={item.style}
                            onChange={(value) =>
                              props.handleColumnComponentChange(
                                value,
                                props.id,
                                i,
                                item.id
                              )
                            }
                          />
                        </SortableItemDragHandle>
                      );
                      break;
                    case "image":
                      return (
                        <SortableItemDragHandle
                          parent={`${i}-${props.id}`}
                          key={item.id}
                          id={item.id}
                          click={() => {
                            setSelected(item.id)
                            setColumn(i)

                          }}                          selected={selected}
                          removeItem={props.removeColumnItem}
                        >
                          <Image
                            id={item.id}
                            active={selected === item.id && true}
                            value={item.value}
                            styles={item.style}
                            onChange={(value) =>
                              props.handleColumnComponentChange(
                                value,
                                props.id,
                                i,
                                item.id
                              )
                            }
                          />
                        </SortableItemDragHandle>
                      );
                      break;
                    default:
                      return null;
                  }
                })}
                {active && props.value.length !== 1 && mouseOver === i && (
                  <button
                    style={{ position: "absolute", right: "0", bottom: "0" }}
                    onClick={() => removeColumn(i)}
                  >
                    <MdClose />
                  </button>
                )}
              </SortableContext>
            </Droppable>
          </Resizable>
        );
      })}
      {active && (
        <button
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => addColumn()}
        >
          +1
        </button>
      )}
    </div>
  );
}

export default Column;
