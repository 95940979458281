import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import { MdFormatAlignLeft, MdFormatAlignCenter, MdFormatAlignRight, MdFormatBold } from 'react-icons/md';
import { BlockPicker } from "react-color";
import '../../styles/EditBar.css'

function EditText(props) {

    const [fontSize, setFontSize] = useState(12)
    const [color, setColor] = useState("")
    const [bold, isBold] = useState(false)



useEffect(() => {
  setFontSize(props.style.fontSize)
  setColor(props.style.color)

}, [props.style])

useEffect(() => {
  if (props.style.fontWeight === undefined || props.style.fontWeight !== "bold" ) {
        props.onChange("fontWeight", "normal")
    } else {
            isBold(true)
        }
}, [])




     function handleToggle() {
        isBold((old) => !old )
        console.log(bold)
        props.onChange("fontWeight", bold ? "bold" : "normal")
    } 

    let colors
    if (props.initialStyle !== undefined) {
    colors = props.initialStyle.colors} else {
        colors = ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8']
    }

    return(
    <div >
        <h1>Opmaak</h1>
        <label>
            Lettergrootte
            <input name="fontSize" value={fontSize} onChange={(e) => props.onChange("fontSize", parseInt(e.target.value))} type="number"/></label>
            <BlockPicker
                        color={color}
                        colors={colors}
                        disableAlpha={true}
                        onChange={(color) => props.onChange("color", color.hex)}
                      />
             <button className={bold ? "bold active" : ""} onClick={() => handleToggle()}><MdFormatBold size="1.4rem"/></button>
    </div>)
}

function EditColumn(props) {

    function updateComponentStyle(property, value, innerProperty, innerValue){
       props.onChange(property, value, innerProperty, innerValue)
    }


    return(
        <div >
            <h1>Kolom</h1>
            <label>
                Marges
            </label>
            <p>{props.selected}</p>
            {props.selected === "shortcode" && <EditText style={props.innerStyle} initialStyle={props.initialStyle} onChange={(a,b) => updateComponentStyle( undefined, undefined, a, b)} />}
            {props.selected === "text" && <EditText style={props.innerStyle} initialStyle={props.initialStyle} onChange={(a,b) => updateComponentStyle( undefined, undefined, a, b)} />}
            {props.selected === "image" && <EditImage style={props.innerStyle} initialStyle={props.initialStyle} onChange={(a,b) => updateComponentStyle( undefined, undefined, a, b)} />}


</div>)
}




function EditImage(props) {

    function convertBase64(file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      }

     async function handleFileRead(event) {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        props.handleChange(base64)
      }

  return (
    <div >
    <h1>Afbeelding</h1>
    <label>
        Kies een afbeelding
        <input type='file' accept="image/png, image/jpeg" onChange={(e) => handleFileRead(e)} />
    </label>
    <br />
    <h2>Opmaak</h2>
    <label>
        Uitlijning
    </label>
    <div style={{display: "flex"}}>
        <button onClick={() => props.onChange("justifyContent", 'flex-start')}>
            <MdFormatAlignLeft />
        </button>
        <button onClick={() => props.onChange("justifyContent", 'center')}>
            <MdFormatAlignCenter />
        </button>
        <button onClick={() => props.onChange("justifyContent", 'flex-end')}>
            <MdFormatAlignRight />
        </button>
    </div>
    </div>
  )
}



function EditBar(props) {
    const [styles, setStyles] = useState({
        color: "#000000",
        fontSize: 12
    })
    const wrapperRef = useRef(null);
    

    

    


    let type
    let innerType = ""



    switch (true) {
        case props.selected.includes("column"):
        type = 'column'
        break;
        case props.selected.includes("text"):
        type = 'text'
        break;
        case props.selected.includes("shortcode"):
        type = 'shortcode'
        break;
        case props.selected.includes("image"):
        type = 'image'
        break;
        default: type = 'page'
    }
    if (props.innerSelected !== undefined) {
    switch (true) {
        case props.innerSelected.includes("column"):
            innerType = 'column'
        break;
        case props.innerSelected.includes("text"):
            innerType = 'text'
        break;
        case props.innerSelected.includes("shortcode"):
            innerType = 'shortcode'
        break;
        case props.innerSelected.includes("image"):
            innerType = 'image'
        break;
        default: innerType = ''
    }
}

function updateComponentStyle(property, value, innerProperty, innerValue){
setStyles(old => ({...old, [property]: value}))
props.callBack(property, value, innerProperty, innerValue)

}

  return(
  <div className="editbar" ref={wrapperRef} >
      {type === "shortcode" && <EditText style={props.style}  initialStyle={props.initialStyle} onChange={updateComponentStyle}/>}
      {type === "text" && <EditText style={props.style}  initialStyle={props.initialStyle} onChange={updateComponentStyle}/>}
      {type === "column" && <EditColumn style={props.style}  initialStyle={props.initialStyle} innerStyle={props.innerSelectedStyle} onChange={updateComponentStyle} handleChange={props.handleChange} selected={innerType}/>}
      {type === "image" && <EditImage style={props.style} initialStyle={props.initialStyle} onChange={updateComponentStyle} handleChange={props.handleChange}/>}
      {type === "page" && ""}
  </div>
  );
}

export default EditBar
