import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { auth, db, functions } from "../firebase";
import { useAuth } from "./AuthContext";

const ApiContext = React.createContext();

export function useApi() {
  return useContext(ApiContext);
}

export function ApiProvider({ children }) {
  const [departments, setDepartments] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [currentPreview, setCurrentPreview] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(true);
  const [token, setToken] = useState("");
  const { currentUser } = useAuth();

  const refresh = functions.httpsCallable("refreshTeamleader");
  const axiosApiInstance = axios.create();

  useEffect(() => {
    if (currentUser) {
  const ref = db.collection("users").doc(currentUser.uid);
  ref.get().then((doc) => {
    localStorage.setItem('accessToken', doc.data().accessToken);
    setToken(doc.data().accessToken);
    refreshData()
  });
  }
}, []);



  async function refreshData() {
        await getDepartments()
        await getQuotations()
        await getInvoices()
        await getUserInfo()

}



  axiosApiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {

      const originalRequest = error.config;
      console.log(originalRequest);
      if (error.response.status === 401 && !originalRequest._retry) {
        console.log("refreshing token")

        originalRequest._retry = true;
        await refresh({
            uid: currentUser.uid,
          }).then(async (response) => {
            console.log(response.data.token);
            localStorage.setItem('accessToken', response.data.token);
            axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.token;
            return axiosApiInstance(originalRequest);
          });;


      }
      return Promise.reject(error);
    }
  );

  async function refreshToken() {
    return await refresh({
      uid: currentUser.uid,
    }).then(async (response) => {
      console.log(response.data.token);
      setToken(response.data.token)
      return response.data.token;
    });
  }



  /// ------ GET DATA ------
  // Get departments
  async function getDepartments() {
    await axiosApiInstance
    .get(`https://api.focus.teamleader.eu/departments.list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: "application/json",
      },
    })
    .then((response) => {
      const array = [];
      response.data.data.map((department) => {
        axios
          .get(`https://api.focus.teamleader.eu/departments.info`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              Accept: "application/json",
            },
            params: {
              id: department.id,
            },
          })
          .then((response) => array.push(response.data.data))
          .catch((err) => console.log(err));
      });
      setDepartments(array);
    })
    .catch(async (error) => {
      console.log(error)
    });
  }


  // Get Quotations

  async function getQuotations() {
    await axiosApiInstance
    .get(`https://api.focus.teamleader.eu/quotations.list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: "application/json",
      },
      params: {
        include: "deal",
      },
    })
    .then((response) => {
      const deal = response.data.included.deal;
      const array = [];


      response.data.data.map(async (quotation) => {
        await axiosApiInstance
          .get(`https://api.focus.teamleader.eu/quotations.info`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              Accept: "application/json",
            },
            params: {
              id: quotation.id,
            },
          })
          .then((response) => {
            const newObj = { ...response.data.data };
            const dealID = newObj.deal.id;
            const matchingDeal = deal.find((item) => item.id === dealID);

            if (matchingDeal) {
            newObj['title'] = `(${matchingDeal.reference}) ${matchingDeal.title}`

            newObj.deal['title'] = matchingDeal.title
            newObj.deal['responsible_user'] = matchingDeal.responsible_user
            newObj.deal['department'] = matchingDeal.department
            newObj.deal['reference'] = matchingDeal.reference
            newObj.deal['lead'] = matchingDeal.lead
            } else {return null}


            array.push(newObj);
          })
          .catch((err) => console.log(err));
      });

      setQuotations(array);
    })
    .catch(async (error) => {
      console.log(error)
    });
  }



  // Get Invoices

  async function getInvoices() {
    await axiosApiInstance
    .get(`https://api.focus.teamleader.eu/invoices.list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: "application/json",
      },
      params: {
        include: "invoicee.customer,department",
      }

    })
    .then((response) => {

        const customers = response.data.included.company
        const department = response.data.included.department
        const array = [];


      response.data.data.map(async (invoice) => {
        await axiosApiInstance
          .get(`https://api.focus.teamleader.eu/invoices.info`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              Accept: "application/json",
            },
            params: {
              id: invoice.id,
            },
          })
          .then((response) => {
           // console.log(response.data)
           const newObj = { ...response.data.data };
           const customerId = newObj.invoicee.customer.id;
           const matchingCustomer = customers.find((item) => item.id === customerId);
           const departmentId = newObj.department.id;
           const matchingDepartment = department.find((item) => item.id === departmentId);

           newObj['title'] = `(${response.data.data.invoice_number}) ${response.data.data.invoicee.name}`
         if (matchingCustomer) {
            newObj.invoicee['primary_address'] = matchingCustomer.primary_address
            newObj.invoicee['website'] = matchingCustomer.website
            newObj.invoicee['emails'] = matchingCustomer.emails
            newObj.invoicee['telephones'] = matchingCustomer.telephones
            newObj.department['name'] = matchingDepartment.name
            newObj.department['vat_number'] = matchingDepartment.vat_number
            } else {return null}

            array.push(newObj);
          })
          .catch((err) => console.log(err));
      });

      setInvoices(array);
    })
    .catch(async (error) => {
      console.log(error)
    });
  }

  async function getUserInfo() {
    await axiosApiInstance
    .get(`https://api.focus.teamleader.eu/users.me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: "application/json",
      },
    })
    .then((response) => { 
        db.collection("users").doc(currentUser.uid).update({
            avatarUrl: response.data.data.avatar_url
        })
    }).catch((err) => console.log(err))

  }



  const value = {
    token,
    departments,
    quotations,
    invoices,
    previewEnabled,
    setPreviewEnabled,
    getQuotations,
    getDepartments,
    getInvoices,
    refreshData,
    

  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

