import React, { useState, useEffect, useRef } from "react";
import {
  DndContext,
  closestCenter,
  rectIntersection,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDraggable,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { v4 as uuid } from "uuid";
import { CSS } from "@dnd-kit/utilities";
import "../App.css";
import { useSortable } from "@dnd-kit/sortable";
import { Page } from "./Page";
import { Drawer } from "./Drawer";
import Text from "./layout/Text";
import Column from "./layout/Column";
import ReactJson from "react-json-view";
import { MdDragIndicator, MdClose } from "react-icons/md";
import EditBar from "./ui/EditBar";
import { Shortcode } from "./layout/Shortcode";
import test from "./templates/test";
import offerte from "./templates/offerte";
import blank from "./templates/blank";
import Image from "./layout/Image";

import { functions } from "../firebase";

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useDraggable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={props.click}
    >
      {props.children}
    </div>
  );
}

export function SortableItemDragHandle(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const [cursor, setCursor] = useState("move");
  const [hovered, setHovered] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
  };

  let selected;

  if (props.selected === props.id) {
    selected = true;
  }

  const dragHandle = (
    <div
      {...listeners}
      {...attributes}
      style={{ cursor: cursor }}
      onMouseDown={() => setCursor("grabbing")}
      onMouseUpCapture={() => setCursor("move")}
    >
      <MdDragIndicator />
    </div>
  );

  let moduleStyle;
  if (selected) {
    moduleStyle = "selected";
  }

  if (!selected && hovered) {
    moduleStyle = "hovermodule";
  }

  return (
    <div
      ref={setNodeRef}
      id={props.id}
      className={moduleStyle}
      style={style}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onClick={() => props.click(props.id)}
    >
      {props.children}
      {selected && (
        <div className="remove">
          {dragHandle}

          <div
            onClick={() => {
              props.removeItem(props.id);
            }}
          >
            <MdClose />
          </div>
        </div>
      )}
    </div>
  );
}

function Generator() {
  const items = ["text", "shortcode", "column", "image"];
  const [selected, setSelected] = useState("");
  const [innerSelected, setInnerSelected] = useState("");
  const [selectedStyle, setSelectedStyle] = useState({});
  const [innerSelectedStyle, setInnerSelectedStyle] = useState({});
  const [initial, setInitial] = useState(test);
  const wrapperRef = useRef(null);


  async function handleSubmit(e) {
    const generateDocX = functions.httpsCallable('generateDocX')
    await generateDocX({
      json: e
    })
  }


  if (selectedStyle === undefined) {
    setSelectedStyle({
      color: "",
      fontSize: undefined,
    })
  }

  // Selected style
    useEffect(() => {
    const pageIndex = initial.pages.findIndex(item => item.content.find((item) => item.id === selected))
    const componentIndex = initial.pages.map(item => item.content.findIndex((item) => item.id === selected))
    if (selected !== "") {
      const styles = initial.pages[pageIndex].content[componentIndex].style
    setSelectedStyle(styles)
    } else {setSelected("")}
   
  }, [selected]) 



  useEffect(() => {
   
    console.log(innerSelected)
    if (innerSelected.substring(2) === "" || innerSelected.substring(2) === "-") {
      return setInnerSelected("")
    } else {
      const column = innerSelected.substring(0, 1)
      const component = innerSelected.substring(2)
      const pageIndex = initial.pages.findIndex(item => item.content.find((item) => item.id === selected))
      const parentIndex = initial.pages.map(item => item.content.findIndex((item) => item.id === selected))
      console.log(parentIndex)
     if (pageIndex >= 0) {
      const componentIndex = initial.pages[pageIndex].content[parentIndex].value[column].value.findIndex((item) => item.id === component)
      
      setInnerSelectedStyle(initial.pages[pageIndex].content[parentIndex].value[column].value[componentIndex].style)

     }

    }
  
  }, [innerSelectedStyle])

  
  /*  // handle click outside
  useEffect(() => {
    document.addEventListener(
      "mousedown",
      function (event) {
        if (event.detail === 1) {
          handleClickOutside(event);
        }
      },
      false
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        function (event) {
          if (event.detail === 1) {
            handleClickOutside(event);
          }
        },
        false
      );
    };
  }, []);

  // handle click outside selected
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSelected("");
    }
  };  */

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 300,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );



  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragEnd={handleDragEnd}
      >
        <div className="app">
          <Drawer id="drawer">

              {items.map((id) => (
                <SortableItem key={id} id={id}>
                  <div style={{ cursor: "pointer" }}>{id}</div>
                </SortableItem>
              ))}
            <button
              onClick={() => {
                setInitial(blank);
                setSelected("")

              }}
            >
              Reset
            </button>
            <button
              onClick={() => {
                setInitial(test);
                setSelected("")
              }}
            >
              Test
            </button>
            <button
              onClick={() => {
                setInitial(offerte);
                setSelected("")

              }}
            >
              Offerte
            </button>
            <button onClick={() => {handleSubmit(initial)}}>
              submit
            </button>
            <p>{selected}</p>
            <p>{innerSelected}</p>
          </Drawer>

          {initial.pages.map((page) => {
            return (
              <Page id={page.id} key={page.id} style={page.styles}>
                <SortableContext id={page.id} items={page.content}>
                  <div ref={wrapperRef}>
                    {page.content.map((component) => {
                      switch (component.type) {
                        case "text":
                          return (
                            <SortableItemDragHandle
                              key={component.id}
                              id={component.id}
                              click={setSelected}
                              removeItem={removeItem}
                              selected={selected}
                            >
                              <Text
                                id={component.id}
                                value={component.value}
                                styles={component.style}
                                onChange={handleChange}
                              />
                            </SortableItemDragHandle>
                          );
                          break;
                        case "column":
                          return (
                            <SortableItemDragHandle
                              key={component.id}
                              id={component.id}
                              click={setSelected}
                              removeItem={removeItem}
                              selected={selected}
                            >
                              <Column
                                id={component.id}
                                selected={selected}
                                innerSelected={(e) => setInnerSelected(e)}
                                value={component.value}
                                active={selected === component.id && true}
                                removeColumn={removeColumn}
                                removeColumnItem={removeColumnItem}
                                addColumn={addColumn}
                                handleColumnResize={handleColumnResize}
                                handleColumnComponentChange={
                                  handleColumnComponentChange
                                }
                              />
                            </SortableItemDragHandle>
                          );
                          break;
                        case "shortcode":
                          return (
                            <SortableItemDragHandle
                              key={component.id}
                              id={component.id}
                              click={setSelected}
                              removeItem={removeItem}
                              selected={selected}
                            >
                              <Shortcode
                                id={component.id}
                                active={selected === component.id && true}
                                defaultValue={component.value}
                                styles={component.style}
                                onChange={handleChange}
                              />
                            </SortableItemDragHandle>
                          );
                          break;
                        case "image":
                          return (
                            <SortableItemDragHandle
                              key={component.id}
                              id={component.id}
                              click={setSelected}
                              removeItem={removeItem}
                              selected={selected}
                            >
                              <Image
                                id={component.id}
                                active={selected === component.id && true}
                                value={component.value}
                                styles={component.style}
                                onChange={handleChange}
                                handleImageResize={handleImageResize}
                              />
                            </SortableItemDragHandle>
                          );
                          break;
                        default:
                          return null;
                      }
                    })}
                  </div>
                </SortableContext>
              </Page>
            );
          })}

          <div ref={wrapperRef}>
          <EditBar
            selected={selected}
            innerSelected={innerSelected}
            innerSelectedStyle={innerSelectedStyle}
            style={selectedStyle}
            initialStyle={initial.style}
            callBack={callBackStyle}
            handleChange={handleChange}
          />
          </div>
        </div>
        <ReactJson collapseStringsAfterLength={100} src={initial} />

      </DndContext>
    </>
  );



  function removeColumnItem(parent, id) {
    const columnIndex = parent.substring(0, 1);
    const moduleId = parent.substring(2);
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === moduleId)
    );
    const moduleIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === moduleId)
    );
    const componentIndex = initial.pages[pageIndex].content[moduleIndex].value[
      columnIndex
    ].value.findIndex((item) => item.id === id);

    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.pages[pageIndex].content[moduleIndex].value[
      columnIndex
    ].value.splice(componentIndex, 1);
    setInnerSelected("")
    setInitial(newArr);
  }

  function removeItem(e) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === e)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === e)
    );
    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.pages[pageIndex].content.splice(componentIndex, 1);

    setInitial(newArr);
    setInnerSelected("")


  }

  function handleChange(value) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === selected)
    );
    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.pages[pageIndex].content[componentIndex].value = value;
    setInitial(newArr);
  }

  function handleColumnComponentChange(value, parent, columnIndex, id) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === parent)
    );
    const parentIndex = initial.pages[pageIndex].content.findIndex(
      (item) => item.id === parent
    );
    const componentIndex = initial.pages[pageIndex].content[parentIndex].value[
      columnIndex
    ].value.findIndex((item) => item.id === id);
    const newArr = JSON.parse(JSON.stringify(initial));

    console.log(componentIndex);
    newArr.pages[pageIndex].content[parentIndex].value[columnIndex].value[
      componentIndex
    ].value = value;
    setInitial(newArr);
  }

  function addColumn() {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === selected)
    );

    // copy JSON array
    const newArr = JSON.parse(JSON.stringify(initial));

    //define content array of collumn module
    const contentArr = newArr.pages[pageIndex].content[componentIndex].value;

    // push new column object to end of content array
    contentArr.push({
      width: undefined,
      style: {
        color: "#000000",
        fontSize: 12,
      },
      value: [],
    });

    //set all columns to equal width
    contentArr.map((item) => {
      item.width = 100 / contentArr.length;
      return item;
    });

    // push array to DOM
    setInitial(newArr);
  }

  function removeColumn(e) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === selected)
    );

    // copy JSON array
    const newArr = JSON.parse(JSON.stringify(initial));

    //define content array of collumn module
    const contentArr = newArr.pages[pageIndex].content[componentIndex].value;

    // remove column from content array

    contentArr.splice(e, 1);

    //set all columns to equal width
    contentArr.map((item) => {
      item.width = 100 / contentArr.length;
      return item;
    });

    // push array to DOM
    setInitial(newArr);
  }

  function handleColumnResize(i, width) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === selected)
    );

    // copy JSON array
    const newArr = JSON.parse(JSON.stringify(initial));

    //define content array of column module
    const contentArr = newArr.pages[pageIndex].content[componentIndex].value;

    console.log(contentArr[i].width);
    contentArr[i].width = contentArr[i].width + width;
    contentArr[i + 1].width = contentArr[i + 1].width - width;
    setInitial(newArr);
  }


function handleImageResize(width, aspectratio, id) {

const pageIndex = initial.pages.findIndex((item) =>
item.content.find((item) => item.id === selected)
);
const componentIndex = initial.pages.map((item) =>
item.content.findIndex((item) => item.id === selected)
);

const newArr = JSON.parse(JSON.stringify(initial));

newArr.pages[pageIndex].content[componentIndex].style["width"] = width;
newArr.pages[pageIndex].content[componentIndex].style["aspectratio"] = aspectratio;

setInitial(newArr);

}







  function handleDragEnd(event) {
    const { active, over } = event;

    const newArr = JSON.parse(JSON.stringify(initial));

    // detect component move
    if (active.data.current !== undefined) {
      // ------ SORTING & MOVING --------

      if (active.id !== over.id) {
        // ----- MOVING -----

        const current = active.data.current.sortable.containerId;
        const currentColumnModule = current.substring(2);
        const currentParentModuleIndex = initial.pages.map((item) =>
          item.content.findIndex((item) => item.id === currentColumnModule)
        );
        const currentColumnIndex = current.substring(0, 1);

        const destination = over.id;
        const destinationColumnModule = destination.substring(2);
        const destinationParentModuleIndex = initial.pages.map((item) =>
          item.content.findIndex((item) => item.id === destinationColumnModule)
        );
        const destinationColumnIndex = destination.substring(0, 1);

        /// MOVING: BETWEEN COLUMNS

        if (!isNaN(currentColumnIndex) && !isNaN(destinationColumnIndex)) {
          // change between columns
          const currentPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === currentColumnModule)
          );

          const destinationPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === destinationColumnModule)
          );
          const currentModuleIndex = initial.pages[currentPageIndex].content[
            currentParentModuleIndex
          ].value[currentColumnIndex].value.findIndex(
            (item) => item.id === active.id
          );
          const currentPath =
            newArr.pages[currentPageIndex].content[currentParentModuleIndex]
              .value[currentColumnIndex].value;
          const destinationPath =
            newArr.pages[destinationPageIndex].content[
              destinationParentModuleIndex
            ].value[destinationColumnIndex].value;

          const currentModule = currentPath[currentModuleIndex];

          destinationPath.push({ ...currentModule });
          currentPath.splice(currentModuleIndex, 1);

          setInitial(newArr);
        } else if (!isNaN(currentColumnIndex) && over.id.includes("page")) {
          // MOVING FROM COLUMN TO PAGE

          const currentPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === currentColumnModule)
          );
          const currentModuleIndex = initial.pages[currentPageIndex].content[
            currentParentModuleIndex
          ].value[currentColumnIndex].value.findIndex(
            (item) => item.id === active.id
          );
          const currentPath =
            newArr.pages[currentPageIndex].content[currentParentModuleIndex]
              .value[currentColumnIndex].value;
          const destinationPageIndex = initial.pages.findIndex(
            (item) => item.id === over.id
          );

          const destinationPath = newArr.pages[destinationPageIndex].content;

          const currentModule = currentPath[currentModuleIndex];

          destinationPath.push({ ...currentModule });
          currentPath.splice(currentModuleIndex, 1);

          setInitial(newArr);
        } else if (!isNaN(destinationColumnIndex) && current.includes("page")) {
          // MOVING FROM PAGE TO COLUMN
          const currentPageIndex = initial.pages.findIndex(
            (item) => item.id === current
          );
          const currentModuleIndex = initial.pages[
            currentPageIndex
          ].content.findIndex((item) => item.id === active.id);
          const currentPath = newArr.pages[currentPageIndex].content;
          const destinationPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === destinationColumnModule)
          );
          const destinationPath =
            newArr.pages[destinationPageIndex].content[
              destinationParentModuleIndex
            ].value[destinationColumnIndex].value;

          const currentModule = currentPath[currentModuleIndex];

          destinationPath.push({ ...currentModule });
          currentPath.splice(currentModuleIndex, 1);
          setInitial(newArr);

          console.log("yes");
        }

        // ----- SORTING -----
        // check if sortable container is a column or not
        const containerId = over.data.current.sortable.containerId.substring(
          0,
          1
        );
        if (!isNaN(containerId)) {
          // ---- COLUMN SORT ORDER ----
          const columnIndex = containerId;
          const moduleId = over.data.current.sortable.containerId.substring(2);
          const pageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === moduleId)
          );
          const moduleIndex = initial.pages.map((item) =>
            item.content.findIndex((item) => item.id === moduleId)
          );
          const columnValues =
            newArr.pages[pageIndex].content[moduleIndex].value[columnIndex]
              .value;

          const oldIndex = columnValues.findIndex(
            (item) => item.id === active.id
          );
          const newIndex = columnValues.findIndex(
            (item) => item.id === over.id
          );

          newArr.pages[pageIndex].content[moduleIndex].value[
            columnIndex
          ].value = arrayMove(columnValues, oldIndex, newIndex);
          setInitial(newArr);
        } else {
          // ---- PAGE SORT ORDER -----

          //sort in page module
          const pageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === over.id)
          );
          const oldIndex = initial.pages.map((item) =>
            item.content.findIndex((item) => item.id === active.id)
          );
          const newIndex = initial.pages.map((item) =>
            item.content.findIndex((item) => item.id === over.id)
          );

          // copy JSON array
          const newArr = JSON.parse(JSON.stringify(initial));

          // set the now order
          newArr.pages[pageIndex].content = arrayMove(
            newArr.pages[pageIndex].content,
            ...oldIndex,
            ...newIndex
          );

          // push JSON array to DOM
          setInitial(newArr);
        }
      }
    }

    //---------------------------------------------------

    // ------- ADDING MODULES ------
    // add new module to PAGE
    if (over.id.substring(0, 4) === "page") {
      const pageIndex = initial.pages.findIndex((item) => item.id === over.id);
      const id = `${active.id}-${uuid()}`
      //check if new module is column or other component and set base object
      if (active.id.includes("column")) {
        newArr.pages[pageIndex].content.push({
          id: id,
          type: active.id,
          value: [
            {
              width: 100,
              value: [],
            },
          ],
        });
      } else {
        newArr.pages[pageIndex].content.push({
          id: id,
          type: active.id,
          style: {
            color: "#000000",
            fontSize: 12,
          },
          value: undefined,
        });
      }

      // push new JSON array to DOM
      setInitial(newArr);
      setSelected(id)
    }

    //add new module to COLUMN
    if (over.id.substring(2, 8) === "column") {
      const columnIndex = over.id.substring(0, 1);
      const moduleId = over.id.substring(2);
      const pageIndex = initial.pages.findIndex((item) =>
        item.content.find((item) => item.id === moduleId)
      );
      const moduleIndex = initial.pages.map((item) =>
        item.content.findIndex((item) => item.id === moduleId)
      );

      if (active.id === "column") {
        return null;
      } else {
        newArr.pages[pageIndex].content[moduleIndex].value[
          columnIndex
        ].value.push({
          id: `${active.id}-${uuid()}`,
          type: active.id,
          style: {
            color: "#000000",
            fontSize: 12,
          },
          value: undefined,
        });

        setInitial(newArr);
      }
    }
  }

  function callBackStyle(property, value, innerProperty, innerValue) {

    const pageIndex = initial.pages.findIndex((item) =>
    item.content.find((item) => item.id === selected)
  );
    const componentIndex = initial.pages.map((item) =>
    item.content.findIndex((item) => item.id === selected)
  );

  const newArr = JSON.parse(JSON.stringify(initial));

    // is outer property?
    if (property) {


    newArr.pages[pageIndex].content[componentIndex].style[property] = value;

    setInitial(newArr);

  } else { // is inside column?
    const column = innerSelected.substring(0, 1)
    const component = innerSelected.substring(2)

    const childIndex = initial.pages[pageIndex].content[componentIndex].value[column].value.findIndex((item) => item.id === component)

    //newArr.pages[pageIndex].content[componentIndex].value[column].value[childIndex].style[property] = value;

    newArr.pages[pageIndex].content[componentIndex].value[column].value[childIndex].style[innerProperty] = innerValue
    setInitial(newArr);
  
  
  }
}


}

export default Generator;
