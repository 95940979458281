const shortCodes = [
  // OWN COMPANY
  {label: "Bedrijfsentiteit",
  options: [{
    shortcode: "$DEPARTMENT_INFO$",
    description: { nl: "Info bedrijfsentiteit" },
  },
  {
    shortcode: "$DEPARTMENT_NAME$",
    description: { nl: "Bedrijfsentiteit: Naam" },
  },
  {
    shortcode: "$DEPARTMENT_ADDRESS$",
    description: { nl: "Bedrijfsentiteit: Adres" },
  },
  {
    shortcode: "$DEPARTMENT_ZIPCODE_CITY$",
    description: { nl: "Bedrijfsentiteit: Postcode Stad" },
  },
  {
    shortcode: "$DEPARTMENT_SECOND_LINE$",
    description: { nl: "Bedrijfsentiteit: Adresregel 2" },
  },
  {
    shortcode: "$DEPARTMENT_REGION$",
    description: { nl: "Bedrijfsentiteit: Regio" },
  },
  {
    shortcode: "$DEPARTMENT_COUNTRY",
    description: { nl: "Bedrijfsentiteit: Land" },
  },
  {
    shortcode: "$DEPARTMENT_EMAIL$",
    description: { nl: "Bedrijfsentiteit: E-mail" },
  },
  {
    shortcode: "$DEPARTMENT_WEBSITE$",
    description: { nl: "Bedrijfsentiteit: Website" },
  },
  {
    shortcode: "$DEPARTMENT_PHONE$",
    description: { nl: "Bedrijfsentiteit: Tel." },
  },
  {
    shortcode: "$DEPARTMENT_FAX$",
    description: { nl: "Bedrijfsentiteit: Fax" },
  },
  {
    shortcode: "$DEPARTMENT_VAT_NUMBER$",
    description: { nl: "Bedrijfsentiteit: Btw-nummer" },
  },
  {
    shortcode: "$DEPARTMENT_DEFAULT_IBAN$",
    description: { nl: "Bedrijfsentiteit: IBAN" },
  },
  {
    shortcode: "$DEPARTMENT_DEFAULT_BIC$",
    description: { nl: "Bedrijfsentiteit: BIC" },
  },
  {
    shortcode: "$DEPARTMENT_NATIONAL_IDENTIFICATION_NUMBER$",
    description: { nl: "Bedrijfsentiteit: Nationaal identificatienummer" },
  },
  { shortcode: "$LOGO$", description: { nl: "Logo" } },
  {
    shortcode: "$RESPONSIBLE_PERSON_CONTACT_DATA$",
    description: { nl: "Verantwoordelijk" },
  },
  {
    shortcode: "$RESPONSIBLE_PERSON_CONTACT_EMAIL$",
    description: { nl: "E-mail verantwoordelijke" },
  },
  {
    shortcode: "$RESPONSIBLE_PERSON_CONTACT_FUNCTION$",
    description: { nl: "Functie verantwoordelijke" },
  },
  {
    shortcode: "$RESPONSIBLE_PERSON_CONTACT_MOBILE$",
    description: { nl: "Gsm verantwoordelijke" },
  },
  {
    shortcode: "$RESPONSIBLE_PERSON_CONTACT_NAME$",
    description: { nl: "Naam verantwoordelijke" },
  },
  {
    shortcode: "$RESPONSIBLE_PERSON_CONTACT_PHONE$",
    description: { nl: "Telefoon verantwoordelijke" },
  },
  {
    shortcode: "$RESPONSIBLE_PERSON_SIGNATURE$",
    description: { nl: "De handtekening van de verantwoordelijke" },
  },
]
},
  //CLIENT INFO
  {label: "Klant",
  options: [{
    shortcode: "$CLIENT_INFO$",
    description: { nl: "Klant" },
  },
  {
    shortcode: "$CLIENT_NAME$",
    description: { nl: "Klant: Naam" },
  },
  {
    shortcode: "$CLIENT_ADDRESS$",
    description: { nl: "Klant: Adres" },
  },
  {
    shortcode: "$CLIENT_STREET_AND_NUMBER$",
    description: { nl: "Klant: Straat en huisnummer" },
  },
  {
    shortcode: "$CLIENT_ZIPCODE$",
    description: { nl: "Klant: Postcode" },
  },
  {
    shortcode: "$CLIENT_CITY$",
    description: { nl: "Klant: Stad" },
  },
  {
    shortcode: "$CLIENT_COUNTRY$",
    description: { nl: "Klant: Land" },
  },
  {
    shortcode: "$CLIENT_TYPE$",
    description: { nl: "Klant: Bedrijfsvorm" },
  },
  {
    shortcode: "$CLIENT_INVOICE_ADDRESS$",
    description: { nl: "Klant: Facturatieadres" },
  },
  {
    shortcode: "$CLIENT_DELIVERY_ADDRESS$",
    description: { nl: "Klant: Leveradres" },
  },
  {
    shortcode: "$CLIENT_VISITING_ADDRESS$",
    description: { nl: "Klant: Bezoekadres" },
  },
  {
    shortcode: "$CLIENT_TAXCODE$",
    description: { nl: "Klant: Btw-nummer" },
  },
  {
    shortcode: "$CLIENT_PHONE$",
    description: { nl: "Klant: Tel." },
  },
  {
    shortcode: "$CLIENT_FAX$",
    description: { nl: "Klant: Fax" },
  },
  {
    shortcode: "$CLIENT_EMAIL$",
    description: { nl: "Klant: E-mail" },
  },
  {
    shortcode: "$CLIENT_WEBSITE$",
    description: { nl: "Klant: Website" },
  },
  {
    shortcode: "$CLIENT_CONTACT_PERSON_INFO$",
    description: { nl: "Klant: Contactpersoon" },
  },
  {
    shortcode: "$CLIENT_PAYMENT_TERM$",
    description: { nl: "Betalingstermijn" },
  },
  {
    shortcode: "$CLIENT_SALUTATION$",
    description: { nl: "Klant: Aanspreking" },
  },
  {
    shortcode: "$CLIENT_NATIONAL_IDENTIFICATION_NUMBER$",
    description: { nl: "Klant: Nationaal identificatienummer" },
  },
  {
    shortcode: "$CONTACT_NAME$",
    description: { nl: "Contactpersoon: Naam" },
  },
  {
    shortcode: "$CONTACT_SALUTATION$",
    description: { nl: "Contactpersoon: Aanspreking" },
  },
  {
    shortcode: "$CLIENT_CONTACT_PERSON_MOBILE$",
    description: { nl: "Contactpersoon: Gsm" },
  },
  {
    shortcode: "$CLIENT_CONTACT_PERSON_PHONE$",
    description: { nl: "Contactpersoon: Tel." },
  },
  {
    shortcode: "$CLIENT_CONTACT_PERSON_FIRST_NAME$",
    description: { nl: "Contactpersoon: Voornaam" },
  },
  {
    shortcode: "$CLIENT_CONTACT_PERSON_LAST_NAME$",
    description: { nl: "Contactpersoon: Achternaam" },
  },]},

  // DOCUMENT
  {label: "Document",
  options: [ {
    shortcode: "$SALE_TITLE$",
    description: { nl: "Titel" },
},
  {
    shortcode: "$SALE_NUMBER$",
    description: { nl: "Nummer" },
},
  {
    shortcode: "$SALE_TITLE_DETAILED$",
    description: { nl: "Titel+Nummer" },
},
  {
    shortcode: "$QUOTATION_PO_NR$",
    description: { nl: "PO-nummer" },
},
  {
    shortcode: "$DATE$",
    description: { nl: "Datum" },
},
  {
    shortcode: "$QUOTATION_TEXT$",
    description: { nl: "Begeleidende tekst" },
},
  {
    shortcode: "$QUOTATION_NAME$",
    description: { nl: "Naam van de offerte" },
},
  {
    shortcode: "$TOTAL_EXCL_VAT$",
    description: { nl: "Totaal excl. btw" },
},
  {
    shortcode: "$TOTAL_INCL_VAT$",
    description: { nl: "Totaal incl. btw" },
},
  {
    shortcode: "$CURRENCY_RATE$",
    description: { nl: "Wisselkoers" },
},
  {
    shortcode: "$DOCUMENT_SIGNATURE_AREA$",
    description: { nl: "Handtekeningen" },
},
  {
    shortcode: "$DOCUMENT_SIGNATURES$",
    description: { nl: "Namen van de verantwoordelijke, de klant en hun handtekening" },
},
  {
    shortcode: "$CLIENT_SIGNATURE$",
    description: { nl: "De handtekening van de klant" },
},
  {
    shortcode: "$PAGEBREAK$",
    description: { nl: "Start een nieuwe pagina" },
},
  {
    shortcode: "$HORIZONTAL_LINE$",
    description: { nl: "Een horizontale lijn over de breedte van de pagina" },
},
  {
    shortcode: "$VALUE_TOTALS$",
    description: { nl: "Totale waarde" },
},]},

// FIXED TEXT
{label: "Vaste tekst",
options: [{
    shortcode: "$TEXT_QUOTATION$",
    description: { nl: "Offerte" },
},
{
    shortcode: "$TEXT_EMAIL$",
    description: { nl: "E-mail" },
},
{
    shortcode: "$TEXT_PHONE$",
    description: { nl: "Tel." },
},
{
    shortcode: "$TEXT_QUOTATION_NR$",
    description: { nl: "Nummer" },
},
{
    shortcode: "$TEXT_TOTAL_EXCL_VAT$",
    description: { nl: "Totaal excl. btw" },
},
{
    shortcode: "$TEXT_LEGAL_NOTES$",
    description: { nl: "Legal notes" },
},
{
    shortcode: "$TEXT_FROM$",
    description: { nl: "Van" },
},
{
    shortcode: "$TEXT_TO$",
    description: { nl: "Tot" },
},
{
    shortcode: "$TEXT_ATTN$",
    description: { nl: "T.a.v." },
},
{
    shortcode: "$LINE_TOTAL_HEADER$",
    description: { nl: "Totaal" },
},
{
    shortcode: "$LINE_TOTAL_INCL_VAT_HEADER$",
    description: { nl: "Totaal incl. btw" },
},
{
    shortcode: "$PRICE_PER_UNIT_HEADER$",
    description: { nl: "Stukprijs" },
},
{
    shortcode: "$PRICE_PER_UNIT_INCL_VAT_HEADER$",
    description: { nl: "Stukprijs incl. btw" },
},
{
    shortcode: "$AMOUNT_HEADER$",
    description: { nl: "Aantal" },
},
{
    shortcode: "$DESCRIPTION_HEADER$",
    description: { nl: "Beschrijving" },
},
{
    shortcode: "$DOLLARSIGN$",
    description: { nl: "Dollarteken ($)" },
},
{
    shortcode: "$LABEL_TOTALS$",
    description: { nl: "Totaal" },
},
{
    shortcode: "$LABEL_END_TOTAL$",
    description: { nl: "Eindtotaal" },
},
{
    shortcode: "$VAT_AMOUNT_HEADER$",
    description: { nl: "Btw-bedrag" },
},
{
    shortcode: "$VAT_PERCENTAGE_HEADER$",
    description: { nl: "Btw %" },
},
{
    shortcode: "$COST_TYPE_HEADER$",
    description: { nl: "Type" },
},
{
    shortcode: "$UNITS_HEADER$",
    description: { nl: "Eenheid" },
},
]},

//TABLES
{label: "Producttabel",
options: [{
    shortcode: "$DESCRIPTION$",
    description: { nl: "Beschrijving" },
},
{
    shortcode: "$LONG_DESCRIPTION$",
    description: { nl: "Lange beschrijving" },
},
{
    shortcode: "$SUBTITLE$",
    description: { nl: "Subtitel" },
},
{
    shortcode: "$SUBTITLE_TOTAL$",
    description: { nl: "Subtotaal" },
},
{
    shortcode: "$AMOUNT$",
    description: { nl: "Aantal" },
},
{
    shortcode: "$PRICE_PER_UNIT$",
    description: { nl: "Stukprijs" },
},
{
    shortcode: "$PRICE_PER_UNIT_INCL_VAT$",
    description: { nl: "Stukprijs incl. btw" },
},
{
    shortcode: "$LINE_TOTAL$",
    description: { nl: "Totaal per lijn" },
},
{
    shortcode: "$LINE_TOTAL_INCL_VAT$",
    description: { nl: "Totaal per lijn (incl. btw)" },
},
{
    shortcode: "$VAT_AMOUNT$",
    description: { nl: "Btw-bedrag" },
},
{
    shortcode: "$VAT_PERCENTAGE$",
    description: { nl: "Btw %" },
},
{
    shortcode: "$COST_TYPE$",
    description: { nl: "Type kost (eenmalig, recurrent,..)" },
},
{
    shortcode: "$UNITS$",
    description: { nl: "Eenheid" },
},]}
];

export default shortCodes;
