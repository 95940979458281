import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/functions'
import 'firebase/compat/storage'


const app = firebase.initializeApp({
    apiKey: "AIzaSyDto-AMZOWFK1FN7neWtD7hElWKCz7oyT8",
    authDomain: "tlgen-7a679.firebaseapp.com",
    projectId: "tlgen-7a679",
    storageBucket: "tlgen-7a679.appspot.com",
    messagingSenderId: "569828598404",
    appId: "1:569828598404:web:0414b6d7153c0f6afbd8d1"
  })


if (window.location.hostname === 'localhost') {
    console.log("testing locally -- hitting local functions and firestore emulators");
    app.functions('europe-west1').useEmulator("localhost", 5001);
  }
  
  export const auth = app.auth()
  export const db = app.firestore()
  export const functions = app.functions('europe-west1')
  export var rtdb = app.database()
  export var storage = app.storage()
  export default app