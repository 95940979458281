import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fab,
  Typography,
  Grow,
  Container,
} from "@mui/material";
import ActionCard from "./ui/ActionCard";
import blank from "./templates/blank.png";
import ActionCardSkeleton from "./ui/ActionCardSkeleton";
import { db, storage } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import CloseIcon from "@mui/icons-material/Close";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "swiper/css";
import "swiper/css/navigation";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const templates = [
  {
    image: blank,
    name: "New design",
    id: "blank",
    description: "Start a new design!",
  },
];

export default function MyTemplates(props) {
  const [open, setOpen] = React.useState(props.show);
  const [myDesigns, setMyDesigns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { currentUser, firstName } = useAuth();

  const swiperRef = useRef(null);
  const myDesignsSwiperRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };

  useEffect(() => {
    if (props.show === false) handleClose();
    if (props.show === true) handleOpen();
  }, [props.show]);

  useLayoutEffect(() => {
    if (currentUser && open) {
      getMyTemplates();
    }
  }, [open]);

  async function getMyTemplates() {
    setLoading(true);
    setMyDesigns([]);
    db.collection("users")
      .doc(currentUser.uid)
      .collection("designs")
      .get()
      .then((templates) => {
        templates.forEach(async (doc) => {
          await storage
            .ref(`users/${currentUser.uid}/designs/${doc.id}.jpg`)
            .getDownloadURL()
            .then((result) => {
              setMyDesigns((old) => [
                ...old,
                {
                  id: doc.id,
                  name: doc.data().title,
                  lastUpdate: doc
                    .data()
                    .lastUpdate.toDate()
                    .toLocaleString("nl-BE", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }),
                  image: result,
                },
              ]);
            });
        });
        setLoading(false);
      });
  }

  async function deleteTemplate(id) {
    await db
      .collection("users")
      .doc(currentUser.uid)
      .collection("designs")
      .doc(id)
      .delete()
      .then(() => {
        storage.ref(`users/${currentUser.uid}/designs/${id}.jpg`).delete();
      })
      .catch((error) => console.log(error));
    getMyTemplates();
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Grow
          in={open}
          unmountOnExit
          easing={{
            enter: "cubic-bezier(0, 1.5, .8, 1)",
            exit: "cubic-bezier(0.4, 0, 0.6, 1)",
          }}
        >
          <Box sx={style}>
            <Fab
              color="primary"
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: "2rem",
                zIndex: 99,
                boxShadow: "none",
              }}
              size="small"
            >
              <CloseIcon />
            </Fab>
            <Container maxWidth="lg">
            <Typography
                  id="transition-modal-title"
                  variant="h5"
                  component="h1"
                  gutterBottom
                  mb={5}
                  pb={2}
                >
                  Welcome {firstName}!
                </Typography>
              <Box
                mb={6}
                sx={{
                  backgroundColor: "#F0F8FF",
                  position: "relative",
                  borderRadius: "15px",
                  padding: "2rem 4rem",
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  gutterBottom
                  pb={2}
                >
                  Start with a template
                </Typography>

                <Swiper
                  ref={swiperRef}
                  style={{ padding: "2px 2px" }}
                  spaceBetween={30}
                  slidesPerView={3}
                  modules={[Navigation]}
                  observeParents
                  observer
                >
                  {templates.map((template, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <ActionCard
                          callback={(e) => props.callback(e)}
                          name={template.name}
                          id={template.id}
                          description={template.description}
                          image={template.image}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                {templates.length > 3 && (
                  <>
                    <div
                      onClick={() => swiperRef.current.swiper.slideNext()}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "1rem",
                        cursor: "pointer",
                        zIndex: 9999,
                      }}
                    >
                      <NavigateNextIcon fontSize="large" color="primary" />
                    </div>
                    <div
                      onClick={() => swiperRef.current.swiper.slidePrev()}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "1rem",
                        cursor: "pointer",
                        zIndex: 9999,
                      }}
                    >
                      <NavigateBeforeIcon fontSize="large" color="primary" />
                    </div>
                  </>
                )}
              </Box>

              {currentUser && (
                <Box
                  mb={6}
                  sx={{
                    backgroundColor: "none",
                    borderRadius: "15px",
                    padding: "2rem 4rem",
                    position: "relative",
                  }}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                    gutterBottom
                    pb={2}
                  >
                    My designs
                  </Typography>

                  <Swiper
                    style={{ padding: "2px 2px" }}
                    spaceBetween={30}
                    slidesPerView={3}
                    modules={[Navigation]}
                    ref={myDesignsSwiperRef}
                    observeParents
                    observer
                  >
                    <SwiperSlide key={0}>
                      <ActionCard
                        callback={(e) => props.callback(e)}
                        name={templates[0].name}
                        id={templates[0].id}
                        description={templates[0].description}
                        image={templates[0].image}
                      />
                    </SwiperSlide>
                    {loading ? (
                      <>
                        <SwiperSlide key={1}>
                          <ActionCardSkeleton />
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                          <ActionCardSkeleton />
                        </SwiperSlide>
                      </>
                    ) : (
                      myDesigns.map((template, i) => {
                        return (
                          <SwiperSlide key={i + 1}>
                            <ActionCard
                              key={i}
                              callback={(e) => props.callback(e)}
                              name={template.name}
                              id={template.id}
                              description={`Last update: ${template.lastUpdate}`}
                              delete={() => deleteTemplate(template.id)}
                              image={template.image}
                              edit
                            />
                          </SwiperSlide>
                        );
                      })
                    )}
                  </Swiper>
                  {myDesigns.length > 2 && (
                    <>
                      <div
                        onClick={() =>
                          myDesignsSwiperRef.current.swiper.slideNext()
                        }
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "1rem",
                          cursor: "pointer",
                          zIndex: 9999,
                        }}
                      >
                        <NavigateNextIcon fontSize="large" color="primary" />
                      </div>
                      <div
                        onClick={() =>
                          myDesignsSwiperRef.current.swiper.slidePrev()
                        }
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "1rem",
                          cursor: "pointer",
                          zIndex: 9999,
                        }}
                      >
                        <NavigateBeforeIcon fontSize="large" color="primary" />
                      </div>
                    </>
                  )}
                </Box>
              )}
            </Container>
          </Box>
        </Grow>
      </Modal>
    </div>
  );
}
