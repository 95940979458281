import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { functions } from "../firebase";


import {
  Backdrop,
  Box,
  Stack,
  Modal,
  Fade,
  Button,
  Typography,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function LoginSignUp(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const { login, signup, resetPassword, currentUser } = useAuth();
  const navigate = useNavigate();
  const refresh = functions.httpsCallable("refreshTeamleader")




  async function handleSignup() {
    signup(email, password)
    handleClose()
    navigate.push('/')
  }

  async function handleLogin(e) {
    e.preventDefault()
    setDisabledLogin(true)
    await login(email, password).then(() => setOpen(false));
    await refresh({
      uid: currentUser.uid
    })
    setDisabledLogin(false)

  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };


  useEffect(() => {
    if (props.show === false) handleClose();
    if (props.show === true) handleOpen();
  }, [props.show]);

  useEffect(() => {
    if ( password !== "" && email !== '') {
      if (password === passwordRepeat) {
      setDisabled(false)
      } else {setDisabled(true)}
    } else {setDisabled(true)}

  }, [password, passwordRepeat, email])
  


  

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Login" {...a11yProps(0)} />
            <Tab label="Sign up" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={value} index={0} dir={theme.direction}>
              <form onSubmit={(e) => handleLogin(e)}>
              <Stack spacing={3} mt={6}>

                <TextField
                  id="email-login"
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  id="password-login"
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button variant="contained" size="large" type="submit" disabled={disabledLogin}>
                  Login
                </Button>
                </Stack>

              </form>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Stack spacing={3} mt={6}>
              <Typography>Sorry, we are not accepting new accounts at this time</Typography>
{/*               <TextField
                id="email-signup"
                label="Email"
                variant="outlined"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id="password-signups"
                label="Password"
                variant="outlined"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                id="password-repeat"
                label="Repeat password"
                variant="outlined"
                type="password"
                required
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                size="large"
                onClick={() => handleSignup()}
                disabled={disabled}
              >
                Sign up
              </Button> */}
            </Stack>
          </TabPanel>
        </Box>
      </Fade>
    </Modal>
  );
}
