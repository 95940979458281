import React, {useState, useEffect} from "react";
import { Resizable } from "re-resizable";
import placeholder from "../../img/placeholder.png";

function Image(props) {

    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [moduleWidth, setModuleWidth] = useState(250)
    const [image, setImage] = useState(placeholder)


/* 
    useEffect(() => {
        const breedte = document.getElementById(props.id).offsetWidth;
        if (breedte) {
        setModuleWidth((old) => breedte);


        if (props.value !== undefined) {
            setImage(props.value)
            setWidth(props.styles.width)
            setHeight(props.styles.aspectratio*(breedte/100*props.styles.width))
        } else {
        setWidth(breedte)
        }
      }
    }, [props.id])  */

    
    useEffect(() => {
        
        if (props.value !== undefined) {
            setImage(props.value)
            setWidth(props.styles.width)
            setHeight(props.styles.aspectratio*(moduleWidth/100*props.styles.width))
        } 
        const breedte = document.getElementById(props.id).offsetWidth;
        setModuleWidth( breedte);

        var img = document.createElement("img");
        img.setAttribute("src", placeholder);
        setTimeout(function () {

            setWidth(breedte)
            setHeight(((breedte/img.width)*img.height))
        }, 0);
    
    }, [image, props.value])




    

  return (
    <div style={{width: "100%", display: 'flex', justifyContent: props.styles.justifyContent !== undefined ? props.styles.justifyContent : 'flex-start'}}>
        <Resizable
          size={{
            width: width,
            height: height,
          }}
          lockAspectRatio
          onResizeStop={(e, direction, ref, d) => {
              const newWidth = width + d.width
              const newHeight = height + d.height
              setWidth(newWidth)
              setHeight(newHeight)
              props.handleImageResize(newWidth/moduleWidth*100, newHeight/newWidth, props.id)

          }}
          maxWidth={moduleWidth}
          style={{
            backgroundImage: `url(${image})`,
            backgroundColor: "none",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            outline: props.active && "1px dashed var(--edit-color-light)"
          }}
        >

        </Resizable>
    </div>
  );
}

export default Image;
