import React, { useState } from "react";
import shortCodes from "../shortCodes";
import { Fade } from "react-awesome-reveal";

import Select from "react-select";

export function Shortcode(props) {
  const options = shortCodes;

  const [value, setValue] = useState("");
  const [mouseOver, setMouseOver] = useState();

  function handleChange(e) {
    setValue(e.shortcode);
    props.onChange(e.shortcode)
  }

  const customStyles = {
    container: () => ({
      position: 'relative',
      zIndex: "0",
    }),
    input: () => ({
      margin: 0,
      padding: 0
    }),

    singleValue: () => ({
      color: props.styles.color,
      fontSize: props.styles.fontSize,
      fontWeight: props.styles.fontWeight !== undefined ? props.styles.fontWeight : "normal",
      position: "absolute",
      width: "100%",
      display: "flex-self",
      justifyContent: "space-between",
      padding: "0",
    }),


    groupHeading: () => ({
      backgroundColor: "var(--lightgray)",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: ".7rem",
      color: "gray",
      padding: ".4rem .7rem",
    }),

    menu: () => ({
      zIndex: "99",
      position: "absolute",
      backgroundColor: "#FFFFFF",
      width: "100%",
      boxShadow: "0px 0px 5px hsla(0, 0%, 66%, 50%)",
      cursor: "pointer",
    }),

    control: () => ({
      border: "none",
      display: "flex",
      position: "relative"
    }),

    indicatorsContainer: () => ({
      display: props.active ? "flex" : "none",
      alignItems: "center",
    }),
  };




  
  return (
    <div
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Select
        openMenuOnClick={false}
        classNamePrefix={props.id}
        placeholder="Selecteer of begin met typen..."
        noOptionsMessage={() => "Geen resultaat"}
        styles={customStyles}
        options={options}
        getOptionLabel={(option) => (
          <div className="autocomplete-label">
            <div>{option.description.nl}</div>{" "}
            {(props.active || mouseOver) && (
              <Fade duration={50}>
                <div className="label-end">{option.shortcode}</div>
              </Fade>
            )}
          </div>
        )}
        getOptionValue={(option) => option.shortcode + option.description.nl}
        getGroupLabel
        defaultValue={() => {
          for (let p of options) {
            for (let i of p.options) {
              if (i.shortcode === props.defaultValue) return i;
            }
          }
        }
        }
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
}
