import React, { useContext, useState, useEffect } from "react";

const StyleContext = React.createContext();

export function useStyle() {
    return useContext(StyleContext);
  }

  export function StyleProvider({ children }) {
    const [selectedStyle, setSelectedStyle] = useState({});

    const value = {
        selectedStyle,
        setSelectedStyle,
      };

  return <StyleContext.Provider value={value}>{children}</StyleContext.Provider>;
}

