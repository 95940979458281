import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export function Page(props) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    color: isOver ? 'green' : undefined,
    backgroundColor: isOver? 'var(--edit-color-light)': undefined,
    padding: "1rem",
    width: "100%",
    height: "100%",
    paddingTop: props.style.marginTop,
    paddingLeft: props.style.marginLeft,
    paddingRight: props.style.marginRight
  };
  
  
  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
}