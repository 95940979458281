import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  CardActionArea,
  IconButton,
  Grid,
  Box,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function ActionCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <Card sx={{ width: "300px", height: "540px" }}>
      <CardActionArea onClick={() => props.callback(props.id)}>
        <CardMedia
          component="img"
          height="420"
          image={props.image}
          alt={props.name}
        />
      </CardActionArea>

      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs="auto">
              <Typography gutterBottom variant="h5" component="div">
                {props.name}
              </Typography>
            </Grid>
            {props.edit && (
              <>
                <Grid item xs={1}>
                  <IconButton aria-describedby={id} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>

                  <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    style={{ zIndex: 2000 }}
                    placement="top-end"
                  >
                    <ClickAwayListener onClickAway={handleClick}>
                      <Paper>
                        <MenuList>
                          <MenuItem sx={{color: "error.main"}} onClick={() => props.delete()}>
                            Delete
                          </MenuItem>
                          <MenuItem>
                            Duplicate
                          </MenuItem>
                          <MenuItem>
                            Rename
                          </MenuItem>
                          <MenuItem>
                            Download
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
