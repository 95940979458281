import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
          // Purple and green play nicely together.
          main: "#2196F3",
          light: "#6ec6ff",
          dark: "#0069c0",
          white: "#FFFFFF",
        },
        secondary: {
          // This is green.A700 as hex.
          main: '#6ec6ff',
        },
        background: {
            main: '#F7F7F7'
        },

      },
      components: {
        // Name of the component
        MuiFilledInput: {
          styleOverrides: {
            // Name of the slot
            root: {
              borderRadius: "10px",
              background: "none",
              border: "none"
            },
            input: {
              // Some CSS
              color: '#FFFFFF',
              fontSize: '1.25rem',
              fontWeight: 500,
              padding: ".25rem 1rem",
            },
          },
        },
      },
      typography: {
        fontFamily: 'Poppins',
        h1: {
          fontFamily: 'Domine',
        },
        h6: {
          fontWeight: 600
        },
      },

  });

export default theme