import React, {useEffect, useState} from 'react';
import useFocus from '../../hooks/useFocus';
import TextareaAutosize from 'react-textarea-autosize';
import {useStyle } from '../../contexts/StyleContext'
function Text(props) {
  let edits = props.style
  const [inputRef, setInputFocus] = useFocus()
  const [style, setStyle] = useState({})

  const { selectedStyle} = useStyle()




  function handleChange(e){
    const target = e.target.value
    props.onChange(target)
    return e
  }


  useEffect(() => {
    setInputFocus(true)
  }, []);

  // set Selected styles
  useEffect(() => {
    if (props.selected === props.id) {
      setStyle(selectedStyle)
    }
  }, [selectedStyle]);

  // set style on first render
  useEffect(() => {
      setStyle(props.styles)
  }, [props.styles]);
   
  
  return(
  <div className='module'>
      <TextareaAutosize 
 placeholder="Begin met typen" autoFocus ref={inputRef} value={props.value} onChange={(e) => handleChange(e)} type="text" style={style} className='inputmodule' />
  </div>
  );
}

export default Text