import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useSnackbar } from 'notistack';
import html2canvas from 'html2canvas';

import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  MuiAppBar,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  Paper,
  Container,
  Stack,
  Tooltip,
  Fab
} from "@mui/material";

import SortableItem from "./ui/SortableItem";
import EditModule from "./ui/EditModule";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DrawerHeader from "./ui/DrawerHeader";
import ImageIcon from "@mui/icons-material/Image";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CodeIcon from "@mui/icons-material/Code";
import TableChartIcon from '@mui/icons-material/TableChart';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  DndContext,
  closestCenter,
  rectIntersection,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDraggable,
  useDroppable,
  DragOverlay
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSwappingStrategy,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { v4 as uuid } from "uuid";
import { CSS } from "@dnd-kit/utilities";
import "../App.css";
import { useSortable } from "@dnd-kit/sortable";
import { Page } from "./Page";
// import { Drawer } from "./Drawer";
import Text from "./layout/Text";
import Column from "./layout/Column";
import ReactJson from "react-json-view";
import { MdDragIndicator, MdClose } from "react-icons/md";
import EditBar from "./ui/EditBar";
import { Shortcode } from "./layout/Shortcode";
import test from "./templates/test";
import offerte from "./templates/offerte";
import blank from "./templates/blank";
import Image from "./layout/Image";
import MyTemplates from "./MyTemplates";
import { db, functions } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import TopBar from "./ui/AppBar";
import { useApi } from "../contexts/ApiContext";
import {useStyle} from '../contexts/StyleContext'

import useUndoableState from "../hooks/useUndoableState";

const drawerWidth = 300;

const ModuleButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "primary.main",
    borderRadius: "10px",
  },
  "&:focus": {
    backgroundColor: "primary.main",
    borderRadius: "10px",
  },
}));

function ModuleDrawer(props) {
  const { setNodeRef } = useDroppable({
    id: "drawer",
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{ position: "fixed", zIndex: "99", left: "2rem", top: "5rem", display: "flex", flexDirection: 'column', alignItems: "center" }}
    >
      <Paper elevation={2} sx={{ padding: ".5rem", borderRadius: "15px" }}>
        <Stack spacing={1}>
          <SortableItem id="image">
            <ModuleButton aria-label="add-image">
              <Tooltip arrow title="Add Image" placement="right">
                <ImageIcon />
              </Tooltip>
            </ModuleButton>
          </SortableItem>

          <SortableItem id="column">
            <ModuleButton aria-label="add-column">
              <Tooltip arrow title="Add Column" placement="right">
                <ViewColumnIcon />
              </Tooltip>
            </ModuleButton>
          </SortableItem>

          <SortableItem id="text">
            <ModuleButton aria-label="add-text">
              <Tooltip arrow title="Add Text" placement="right">
                <TextFieldsIcon />
              </Tooltip>
            </ModuleButton>
          </SortableItem>

          <SortableItem id="shortcode">
            <ModuleButton aria-label="add-shortcode">
              <Tooltip arrow title="Add Shortcode" placement="right">
                <CodeIcon />
              </Tooltip>
            </ModuleButton>
          </SortableItem>

          <SortableItem id="table">
            <ModuleButton aria-label="add-table">
              <Tooltip arrow title="Add Product Table" placement="right">
                <TableChartIcon />
              </Tooltip>
            </ModuleButton>
          </SortableItem>
        </Stack>
      </Paper>
      <Stack mt={10} spacing={1}>
      <Fab  color="primary" size="small" aria-label="add">
      <ZoomInIcon onClick={() => props.setZoom((zoom) => zoom+10)} />
</Fab>
      <Fab  color="primary" size="small" aria-label="add">
      <ZoomOutIcon onClick={() => props.setZoom((zoom) => zoom-10)} />
</Fab>
      </Stack>
    </Box>
  );
}




export function SortableItemDragHandle(props) {
    const Element = props.element || 'div';
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: props.id });
  
    const [cursor, setCursor] = useState("grab");
    const [hovered, setHovered] = useState(false);

    const style = {
      transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)`  : undefined,
      transition,
      position: "relative",
    };
  
    let selected;
  
    if (props.selected === props.id) {
      selected = true;
    }
  
    const dragHandle = (
      <div
        {...listeners}
        {...attributes}
        style={{ cursor: cursor }}
        onMouseDown={() => setCursor("grabbing")}
        onMouseUpCapture={() => setCursor("grab")}
      >
        <MdDragIndicator size="1.3rem"/>
      </div>
    );
  
    let moduleStyle;
    if (selected) {
      moduleStyle = "selected";
    }
  
    if (!selected && hovered) {
      moduleStyle = "hovermodule";
    }
  
    return (
      <Element
        ref={setNodeRef}
        id={props.id}
        className={moduleStyle}
        style={style}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={() => props.click(props.id)}
      >
        {props.children}
        {selected && (<>
          <div className="move">
            {dragHandle}
  
          </div>
          <div className="remove"
              onClick={() => {
                props.removeItem(props.id);
              }}
            >
              <MdClose />
            </div>
          </>
        )}
      </Element>
    );
  }


  

export default function Editor() {
  const [open, setOpen] = useState(false);
  const [showTemplatesModal, setShowtemplatesModal] = useState(true)
  const [selected, setSelected] = useState("");
  const [innerSelected, setInnerSelected] = useState("");
  const [innerSelectedStyle, setInnerSelectedStyle] = useState({});
  //const [initial, setInitial] = useState(blank);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false)
  const [viewPageTop, setViewAddPageTop] = useState(false)
  const [zoom, setZoom] = useState(100)
  const [documentId, setDocumentId] = useState("")
  const { enqueueSnackbar } = useSnackbar();


  const printRef = useRef(null)

  const { departments, quotations, refreshData, invoices } = useApi()

  const { setSelectedStyle , selectedStyle} = useStyle()
  const {currentUser} = useAuth()

  const {
    state: initial,
    setState: setInitial,
    resetState: resetDoc,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    goBack: undoDoc,
    goForward: redoDoc
  } = useUndoableState(blank);

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;
  

  const wrapperRef = useRef(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 300,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  async function handleDrawerClose() {
    setSelected("")
    setOpen(false);

  };

  async function handleSubmit(e) {
    setSelected("")

      setLoading(true)
      const preview = await handlePreviewImage()
    const generateDocX = functions.httpsCallable('generateDocX')
    await generateDocX({
      json: e,
      preview: preview,
      uid: currentUser.uid,
      documentId: documentId,
    }).then((a) => {
        if (a.data.status === "success") {
            setLoading(false)
            enqueueSnackbar('Document exported!', { variant: "success" })
        } else {            setLoading(false)
            enqueueSnackbar('Something went wrong', { variant: "error" })}


    }

        )
  }

  async function handleLoad(e) {
      setSelected("")
      setInnerSelected("")
    setShowtemplatesModal(false)
      setLoading(true)
      setInitial(e)
      setName(initial.name)
      setLoading(false)
  }


  useEffect(() => {
    setName(initial.name)
  
    return name
  }, [showTemplatesModal])


  const handlePreviewImage = async () => {
    setLoading(true)

    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/jpg');

   return data.substring(22)
  };

   // Set selected style
   useEffect(() => {
      if (!open) {setOpen(true)}
      const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );    
    const componentIndex = initial.pages.map(item => item.content.findIndex((item) => item.id === selected))
    if (selected !== "") {
      const styles = initial.pages[pageIndex].content[componentIndex].style
    setSelectedStyle(styles)
    } else {setSelected("")}
   
  }, [selected])  
 

  // Set inner selected style
/*   useEffect(() => {
   
    if (innerSelected.substring(2) === "" || innerSelected.substring(2) === "-") {
      return setInnerSelected("")
    } else {
      const column = innerSelected.substring(0, 1)
      const component = innerSelected.substring(2)
      const pageIndex = initial.pages.findIndex(item => item.content.find((item) => item.id === selected))
      const parentIndex = initial.pages.map(item => item.content.findIndex((item) => item.id === selected))
     if (pageIndex >= 0) {
      const componentIndex = initial.pages[pageIndex].content[parentIndex].value[column].value.findIndex((item) => item.id === component)
      
      setInnerSelectedStyle(initial.pages[pageIndex].content[parentIndex].value[column].value[componentIndex].style)

     }

    }
  
  }, [innerSelected, selected]) */

  useEffect(() => {
    if (!open) {setOpen(true)}
  }, [selected])
  



  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      <MyTemplates
        callback={async (e) => {
          let template;
          if (e === "blank") {
            template = blank;
            const templateId = uuid()
            handleLoad(template);
            setDocumentId(templateId)
            resetDoc(template)
          } else {
            const ref = await db.collection('users').doc(currentUser.uid).collection('designs').doc(e)
            ref.get().then((doc) => {
              console.log()
              setDocumentId(e)
              handleLoad(doc.data().json);
              resetDoc(doc.data().json)
            })

          }
        }}
        handleClose={() => setShowtemplatesModal(false)}
        show={showTemplatesModal}
      />
      <TopBar
        name={initial.name}
        undo={undoDoc}
        redo={redoDoc}
        canUndo={canUndo}
        canRedo={canRedo}
        loading={loading}
        changeName={changeName}
        download={() => handleSubmit(initial)}
        showTemplates={() => setShowtemplatesModal(true)}
      />

      <Box
        sx={{
          backgroundColor: "background.main",
          minHeight: "100%",
          width: "100%",
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragMove={handleDragStart}
        >
          <ModuleDrawer setZoom={setZoom} />
          <Container
            maxWidth="md"
            sx={{
              backgroundColor: "background.main",
              zoom: `${zoom}%`,

              minHeight: "100%",
            }}
          >
            <DrawerHeader />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "793.70px",
                  height: "1122.52px",
                },
              }}

            >
              {initial.pages.map((page, i) => {
                return (
                  <Paper square elevation={2} style={{ position: "relative" }} ref={i === 0 ? printRef : null}>
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{
                        position: "absolute",
                        left: "-3.5rem",
                        textAlign: "end",
                      }}
                      color="#AAAAAA"
                    >
                      Page {i + 1}
                    </Typography>
                    <div
                      onMouseEnter={() => setViewAddPageTop((old) => !old)}
                      onMouseLeave={() => setViewAddPageTop((old) => !old)}
                      style={{
                        position: "absolute",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        left: "0",
                        top: "-1.3rem",
                      }}
                    >
                      <Tooltip arrow title="Add Page" placement="bottom">
                        <IconButton
                          onClick={() => addPageTop(page.id, i)}
                          size="small"
                        >
                          {viewPageTop && (
                            <Fade duration={100}>
                              <AddCircleIcon color="primary" />
                            </Fade>
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>

                    <Page id={page.id} key={page.id} style={page.styles}>
                      <SortableContext id={page.id} items={page.content} strategy={verticalListSortingStrategy}>
                        <div ref={wrapperRef}>
                          {page.content.map((component) => {
                            switch (component.type) {
                              case "text":
                                return (
                                  <SortableItemDragHandle
                                    key={component.id}
                                    id={component.id}
                                    click={setSelected}
                                    removeItem={removeItem}
                                    selected={selected}
                                  >
                                    <Text
                                      id={component.id}
                                      value={component.value}
                                      styles={component.style}
                                      onChange={handleChange}
                                      selected={selected}

                                    />
                                  </SortableItemDragHandle>
                                );
                                break;
                              case "column":
                                return (
                                  <SortableItemDragHandle
                                    key={component.id}
                                    id={component.id}
                                    click={setSelected}
                                    removeItem={removeItem}
                                    selected={selected}
                                  >
                                    <Column
                                      id={component.id}
                                      selected={selected}
                                      innerSelected={(e) => setInnerSelected(e)}
                                      value={component.value}
                                      active={selected === component.id && true}
                                      removeColumn={removeColumn}
                                      removeColumnItem={removeColumnItem}
                                      addColumn={addColumn}
                                      handleColumnResize={handleColumnResize}
                                      handleColumnComponentChange={
                                        handleColumnComponentChange
                                      }
                                    />
                                  </SortableItemDragHandle>
                                );
                                break;
                              case "shortcode":
                                return (
                                  <SortableItemDragHandle
                                    key={component.id}
                                    id={component.id}
                                    click={setSelected}
                                    removeItem={removeItem}
                                    selected={selected}
                                  >
                                    <Shortcode
                                      id={component.id}
                                      active={selected === component.id && true}
                                      defaultValue={component.value}
                                      styles={component.style}
                                      onChange={handleChange}
                                    />
                                  </SortableItemDragHandle>
                                );
                                break;
                              case "image":
                                return (
                                  <SortableItemDragHandle
                                    key={component.id}
                                    id={component.id}
                                    click={setSelected}
                                    removeItem={removeItem}
                                    selected={selected}
                                  >
                                    <Image
                                      id={component.id}
                                      active={selected === component.id && true}
                                      value={component.value}
                                      styles={component.style}
                                      onChange={handleChange}
                                      handleImageResize={handleImageResize}
                                    />
                                  </SortableItemDragHandle>
                                );
                                break;
                              default:
                                return null;
                            }
                          })}
                        </div>
                      </SortableContext>
                    </Page>
                  </Paper>
                );
              })}
              <ReactJson
                //collapsed
                collapseStringsAfterLength={100}
                src={initial}
              />

            </Box>
          </Container>
        </DndContext>
      </Box>
      <IconButton
        onClick={handleDrawerOpen}
        sx={{
          position: "absolute",
          top: "4rem",
          right: "1rem",
          backgroundColor: "white",
        }}
      >
        <ChevronLeftIcon />
      </IconButton>

      <EditModule
        open={open}
        handleDrawerClose={handleDrawerClose}
        selected={selected}
        innerSelected={innerSelected}
        innerSelectedStyle={innerSelectedStyle}
        style={selectedStyle}
        initialStyle={initial.style}
        callBack={callBackStyle}
        handleChange={handleChange}
      />
    </Box>
  );

  
  function addPageTop(id, key){
    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.pages.unshift({
        id: `page-${uuid()}`,
        styles: {
            "marginLeft": 50,
            "marginRight": 50
          },
        "content": [],
    })

    setInitial(newArr);

  }


  function changeName(e) {
    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.name = e
    setInitial(newArr);

  }
  

  function removeColumnItem(parent, id) {
    const columnIndex = parent.substring(0, 1);
    const moduleId = parent.substring(2);
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === moduleId)
    );
    const moduleIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === moduleId)
    );
    const componentIndex = initial.pages[pageIndex].content[moduleIndex].value[
      columnIndex
    ].value.findIndex((item) => item.id === id);

    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.pages[pageIndex].content[moduleIndex].value[
      columnIndex
    ].value.splice(componentIndex, 1);
    setInnerSelected("")
    setInitial(newArr);
  }

  function removeItem(e) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === e)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === e)
    );
    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.pages[pageIndex].content.splice(componentIndex, 1);

    setInitial(newArr);
    setInnerSelected("")


  }

  function handleChange(value) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages[pageIndex].content.findIndex((item) => item.id === selected);
    const newArr = JSON.parse(JSON.stringify(initial));

    console.log([componentIndex])
    newArr.pages[pageIndex].content[componentIndex].value = value;
    setInitial(newArr);
  }

  function handleColumnComponentChange(value, parent, columnIndex, id) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === parent)
    );
    const parentIndex = initial.pages[pageIndex].content.findIndex(
      (item) => item.id === parent
    );
    const componentIndex = initial.pages[pageIndex].content[parentIndex].value[
      columnIndex
    ].value.findIndex((item) => item.id === id);
    const newArr = JSON.parse(JSON.stringify(initial));

    newArr.pages[pageIndex].content[parentIndex].value[columnIndex].value[
      componentIndex
    ].value = value;
    setInitial(newArr);
  }

  function addColumn() {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === selected)
    );

    // copy JSON array
    const newArr = JSON.parse(JSON.stringify(initial));

    //define content array of collumn module
    const contentArr = newArr.pages[pageIndex].content[componentIndex].value;

    // push new column object to end of content array
    contentArr.push({
      width: undefined,
      style: {
        color: "#000000",
        fontSize: 12,
      },
      value: [],
    });

    //set all columns to equal width
    contentArr.map((item) => {
      item.width = 100 / contentArr.length;
      return item;
    });

    // push array to DOM
    setInitial(newArr);
  }

  function removeColumn(e) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === selected)
    );

    // copy JSON array
    const newArr = JSON.parse(JSON.stringify(initial));

    //define content array of collumn module
    const contentArr = newArr.pages[pageIndex].content[componentIndex].value;

    // remove column from content array

    contentArr.splice(e, 1);

    //set all columns to equal width
    contentArr.map((item) => {
      item.width = 100 / contentArr.length;
      return item;
    });

    // push array to DOM
    setInitial(newArr);
  }

  function handleColumnResize(i, width) {
    const pageIndex = initial.pages.findIndex((item) =>
      item.content.find((item) => item.id === selected)
    );
    const componentIndex = initial.pages.map((item) =>
      item.content.findIndex((item) => item.id === selected)
    );

    // copy JSON array
    const newArr = JSON.parse(JSON.stringify(initial));

    //define content array of column module
    const contentArr = newArr.pages[pageIndex].content[componentIndex].value;

    contentArr[i].width = contentArr[i].width + width;
    contentArr[i + 1].width = contentArr[i + 1].width - width;
    setInitial(newArr);
  }


function handleImageResize(width, aspectratio, id) {

const pageIndex = initial.pages.findIndex((item) =>
item.content.find((item) => item.id === selected)
);
const componentIndex = initial.pages.map((item) =>
item.content.findIndex((item) => item.id === selected)
);

const newArr = JSON.parse(JSON.stringify(initial));

newArr.pages[pageIndex].content[componentIndex].style["width"] = width;
newArr.pages[pageIndex].content[componentIndex].style["aspectratio"] = aspectratio;

setInitial(newArr);

}







  function handleDragEnd(event) {
    const { active, over } = event;

    const newArr = JSON.parse(JSON.stringify(initial));

    // detect component move
    if (active.data.current !== undefined) {
      // ------ SORTING & MOVING --------

      if (active.id !== over.id) {
        // ----- MOVING -----

        const current = active.data.current.sortable.containerId;
        const currentColumnModule = current.substring(2);
        const currentParentModuleIndex = initial.pages.map((item) =>
          item.content.findIndex((item) => item.id === currentColumnModule)
        );
        const currentColumnIndex = current.substring(0, 1);

        const destination = over.id;
        const destinationColumnModule = destination.substring(2);
        const destinationParentModuleIndex = initial.pages.map((item) =>
          item.content.findIndex((item) => item.id === destinationColumnModule)
        );
        const destinationColumnIndex = destination.substring(0, 1);

        /// MOVING: BETWEEN COLUMNS

        if (!isNaN(currentColumnIndex) && !isNaN(destinationColumnIndex)) {
          // change between columns
          const currentPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === currentColumnModule)
          );

          const destinationPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === destinationColumnModule)
          );
          const currentModuleIndex = initial.pages[currentPageIndex].content[
            currentParentModuleIndex
          ].value[currentColumnIndex].value.findIndex(
            (item) => item.id === active.id
          );
          const currentPath =
            newArr.pages[currentPageIndex].content[currentParentModuleIndex]
              .value[currentColumnIndex].value;
          const destinationPath =
            newArr.pages[destinationPageIndex].content[
              destinationParentModuleIndex
            ].value[destinationColumnIndex].value;

          const currentModule = currentPath[currentModuleIndex];

          destinationPath.push({ ...currentModule });
          currentPath.splice(currentModuleIndex, 1);

          setInitial(newArr);
        } else if (!isNaN(currentColumnIndex) && over.id.includes("page")) {
          // MOVING FROM COLUMN TO PAGE

          const currentPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === currentColumnModule)
          );
          const currentModuleIndex = initial.pages[currentPageIndex].content[
            currentParentModuleIndex
          ].value[currentColumnIndex].value.findIndex(
            (item) => item.id === active.id
          );
          const currentPath =
            newArr.pages[currentPageIndex].content[currentParentModuleIndex]
              .value[currentColumnIndex].value;
          const destinationPageIndex = initial.pages.findIndex(
            (item) => item.id === over.id
          );

          const destinationPath = newArr.pages[destinationPageIndex].content;

          const currentModule = currentPath[currentModuleIndex];

          destinationPath.push({ ...currentModule });
          currentPath.splice(currentModuleIndex, 1);

          setInitial(newArr);
        } else if (!isNaN(destinationColumnIndex) && current.includes("page")) {
          // MOVING FROM PAGE TO COLUMN
          const currentPageIndex = initial.pages.findIndex(
            (item) => item.id === current
          );
          const currentModuleIndex = initial.pages[
            currentPageIndex
          ].content.findIndex((item) => item.id === active.id);
          const currentPath = newArr.pages[currentPageIndex].content;
          const destinationPageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === destinationColumnModule)
          );
          const destinationPath =
            newArr.pages[destinationPageIndex].content[
              destinationParentModuleIndex
            ].value[destinationColumnIndex].value;

          const currentModule = currentPath[currentModuleIndex];

          destinationPath.push({ ...currentModule });
          currentPath.splice(currentModuleIndex, 1);
          setInitial(newArr);

        }

        // ----- SORTING -----
        // check if sortable container is a column or not
        const containerId = over.data.current.sortable.containerId.substring(
          0,
          1
        );
        if (!isNaN(containerId)) {
          // ---- COLUMN SORT ORDER ----
          const columnIndex = containerId;
          const moduleId = over.data.current.sortable.containerId.substring(2);
          const pageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === moduleId)
          );
          const moduleIndex = initial.pages.map((item) =>
            item.content.findIndex((item) => item.id === moduleId)
          );
          const columnValues =
            newArr.pages[pageIndex].content[moduleIndex].value[columnIndex]
              .value;

          const oldIndex = columnValues.findIndex(
            (item) => item.id === active.id
          );
          const newIndex = columnValues.findIndex(
            (item) => item.id === over.id
          );

          newArr.pages[pageIndex].content[moduleIndex].value[
            columnIndex
          ].value = arrayMove(columnValues, oldIndex, newIndex);
          setInitial(newArr);
        } else {
          // ---- PAGE SORT ORDER -----

          //sort in page module
          const pageIndex = initial.pages.findIndex((item) =>
            item.content.find((item) => item.id === over.id)
          );
          const oldIndex = initial.pages.map((item) =>
            item.content.findIndex((item) => item.id === active.id)
          );
          const newIndex = initial.pages.map((item) =>
            item.content.findIndex((item) => item.id === over.id)
          );

          // copy JSON array
          const newArr = JSON.parse(JSON.stringify(initial));

          // set the now order
          newArr.pages[pageIndex].content = arrayMove(
            newArr.pages[pageIndex].content,
            ...oldIndex,
            ...newIndex
          );

          // push JSON array to DOM
          setInitial(newArr);
        }
      }
    }

    //---------------------------------------------------

    // ------- ADDING MODULES ------
    // add new module to PAGE
    if (over.id.substring(0, 4) === "page") {
      const pageIndex = initial.pages.findIndex((item) => item.id === over.id);
      const id = `${active.id}-${uuid()}`
      //check if new module is column or other component and set base object
      if (active.id.includes("column")) {
        newArr.pages[pageIndex].content.push({
          id: id,
          type: active.id,
          value: [
            {
              width: 100,
              value: [],
            },
          ],
        });
      } else {
        newArr.pages[pageIndex].content.push({
          id: id,
          type: active.id,
          style: {
            color: "#000000",
            fontSize: 12,
          },
          value: undefined,
        });
      }

      // push new JSON array to DOM
      setInitial(newArr);
      setSelected(id)
    }

    //add new module to COLUMN
    if (over.id.substring(2, 8) === "column") {
      const columnIndex = over.id.substring(0, 1);
      const moduleId = over.id.substring(2);
      const pageIndex = initial.pages.findIndex((item) =>
        item.content.find((item) => item.id === moduleId)
      );
      const moduleIndex = initial.pages.map((item) =>
        item.content.findIndex((item) => item.id === moduleId)
      );

      if (active.id === "column") {
        return null;
      } else {
        newArr.pages[pageIndex].content[moduleIndex].value[
          columnIndex
        ].value.push({
          id: `${active.id}-${uuid()}`,
          type: active.id,
          style: {
            color: "#000000",
            fontSize: 12,
          },
          value: undefined,
        });

        setInitial(newArr);
      }
    }
  }

  function handleDragStart(event) {
    const { active, over } = event;

  }

  function callBackStyle(property, value, innerProperty, innerValue) {

    const pageIndex = initial.pages.findIndex((item) =>
    item.content.find((item) => item.id === selected)
  );
    const componentIndex = initial.pages.map((item) =>
    item.content.findIndex((item) => item.id === selected)
  );


  const newArr = JSON.parse(JSON.stringify(initial));

    // is outer property?
    if (property) {


    newArr.pages[pageIndex].content[componentIndex].style[property] = value;
    const styles = newArr.pages[pageIndex].content[componentIndex].style

    setInitial(newArr);
    setSelectedStyle(styles)



  } else { // is inside column?
    const column = innerSelected.substring(0, 1)
    const component = innerSelected.substring(2)

    const childIndex = initial.pages[pageIndex].content[componentIndex].value[column].value.findIndex((item) => item.id === component)

    //newArr.pages[pageIndex].content[componentIndex].value[column].value[childIndex].style[property] = value;

    newArr.pages[pageIndex].content[componentIndex].value[column].value[childIndex].style[innerProperty] = innerValue
    setInitial(newArr);
  
  
  }
}
}
