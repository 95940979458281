const blank = {
    "name": "New design",
    "pages": [
      {
        "id": "page-e821354b-e7c6-429f-a7f9-3e6cbd7ee270",
        "styles": {
          "marginTop": 80,
          "marginLeft": 50,
          "marginRight": 50
        },
        "content": []
      }
    ]
  }

export default blank