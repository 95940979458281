import React, { useEffect , useRef} from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fab,
  Typography,
  Grow,
  Container,
  Fade,
  Link,
  Avatar,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Slide,
  LinearProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserInfo from "./UserInfo";
import { DataGrid } from "@mui/x-data-grid";
import { useSnackbar } from 'notistack';
import { functions, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  pt: "6rem",
  overflowY: "auto",
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: "40px",
      width: "40px",
    },
    children: `${name.split(" ")[0][0].toUpperCase()}`,
  };
}

function pictureAvatar(name) {
  return {
    sx: {
      backgroundImage: `url(${name})`,
      backgroundSize: 'cover',
      height: "40px",
      width: "40px",
      border: "1px solid lightgray"
    },
    children: ``,

};
}

export default function Users(props) {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [userDetail, setUserDetail] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [trialDuration, setTrialDuration] = React.useState(0);
  const [showInvite, setShowInvite] = React.useState(false);
const slideRef = useRef(null)
const [inviteEmail, setInviteEmail] = React.useState('')
const [disabled, setDisabled] = React.useState(false)
const { enqueueSnackbar } = useSnackbar();



  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUserDetail(false);
    setUser("")
    setShowInvite(false)
    props.handleClose(false);
  };

  // get data on open
  useEffect(() => {
    if(open === true) {
        getData()};
  }, [open]);

// sync props with state
  useEffect(() => {
    if (props.show === false) handleClose();
    if (props.show === true) handleOpen();
  }, [props.show]);



  // make a user an admin


  // query DB for list of users
  async function getData() {
    setLoading(true);
    setUsers([]);
    await db
      .collection("users")
      .get()
      .then((users) =>
        users.forEach((user) => {
          setUsers((old) => [
            ...old,
            {
              id: user.id,
              avatarUrl: user.data().avatarUrl,
              firstName: user.data().firstName,
              lastName: user.data().lastName,
              email: user.data().email,
              subscription: user.data().subscription.charAt(0).toUpperCase() + user.data().subscription.slice(1),
              accountCreated: user.data().accountCreated.toDate().toLocaleString('nl-BE', {day: 'numeric', month: "short", year: "numeric", hour: "numeric", minute: "numeric"}),
              admin: user.data().admin,
              active: user.data().active,
            },
          ]);
        })
      );
    setLoading(false);
  }

  // open detailed user view
  function loadUser(id) {
    setUser(id)
    setUserDetail(true)
  }

  async function handleInvite() {
      const inviteUser = functions.httpsCallable('inviteUser')
      setDisabled(true)
      await inviteUser({
        email: inviteEmail,
        trialDuration: trialDuration
      }).then((data) => {
        if (data.data.errorInfo) {
          enqueueSnackbar(data.data.errorInfo.message, { variant: "error" })
        } else {
          enqueueSnackbar(data.data.message, { variant: "success" })      
        }
      }).catch(err => console.log(err))
      setShowInvite(false)
      getData()
      setDisabled(false)
  }


  // define table columns
  const columns = [
    {
      field: "active",
      headerName: "",
      align: "center",
      width: 60,
      type: "action",
      renderCell: (params) => {
        return (
          <div style={{ position: "relative" }}>
            <Avatar
              {...params.row.avatarUrl === "" || params.row.avatarUrl === undefined ? {...stringAvatar(params.row.email)} : {...pictureAvatar(params.row.avatarUrl)}}
            
            />
            <div
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: params.value ? "limegreen" : "red",
                position: "absolute",
                right: "0",
                bottom: "0",
                borderRadius: "50%",
                border: "1.5px solid white",
              }}
            />
          </div>
        );
      },
    },
    { field: "firstName", headerName: "First name", minWidth: 150, flex: 1 },
    { field: "lastName", headerName: "Last Name", minWidth: 150, flex: 1 },
    {
      field: "email",
      headerName: "Email",
      type: "link",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link color="inherit" href={`mailto:${params.value}`}>
            {params.value}
          </Link>
        );
      },
    },
    { field: "subscription", headerName: "Subscription type", minWidth: 100, flex: 1 },
    { field: "accountCreated", headerName: "Created at", type: "date", minWidth: 100, flex: 1 },
   
  ];



  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Grow
          in={open}
          unmountOnExit
          easing={{
            enter: "cubic-bezier(0, 1.5, .8, 1)",
            exit: "cubic-bezier(0.4, 0, 0.6, 1)",
          }}
        >
          <Box sx={style}>

            <Fab
              color="primary"
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "fixed",
                right: "3rem",
                top: "2rem",
                zIndex: 99,
                boxShadow: "none",
              }}
              size="small"
            >
              <CloseIcon />
            </Fab>
            {!userDetail && (
              <Fade in>
                <Container maxWidth="lg">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      overflow: "hidden",
                      position: "relative"
                    }}
                    ref={slideRef}
                  >
                    <Typography
                      id="transition-modal-title"
                      variant="h4"
                      component="h2"
                      pb={2}
                    >
                      Users
                    </Typography>
                    {!showInvite && <Fade timeout={500} in><Button onClick={() => setShowInvite(true)}>Invite user</Button></Fade>}
                    <Slide direction="left" in={showInvite} container={slideRef.current}><Box sx={{position: 'absolute', right: 0}}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <TextField
                          sx={{width: "300px"}}
                            size="small"
                            label="Email"
                            type="email"
                            variant="outlined"
                            onChange={(e) => setInviteEmail(e.target.value)}
                          />
                        </Grid>
                        <Grid item>
                        <FormControl variant="outlined" sx={{minWidth: 120 }}>

                          <InputLabel id="trial-duration-label">
                          Trial duration
                          </InputLabel>
                          <Select
                            labelId="trial-duration-label"
                            id="trial-duration"
                            value={trialDuration}
                            label="Trial duration"
                            onChange={(e) => setTrialDuration(e.target.value)}
                            size="small"
                            width={200}
                          >
                            <MenuItem value={0}>none</MenuItem>
                            <MenuItem value={7}>7 days</MenuItem>
                            <MenuItem value={14}>14 days</MenuItem>
                            <MenuItem value={28}>28 days</MenuItem>
                          </Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={() => handleInvite()} disabled={disabled}>Invite</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => setShowInvite(false)} disabled={disabled}>Cancel</Button>
                        </Grid>
                      </Grid>
                    </Box>
                    </Slide>
                  </Box>
                  <div style={{ height: 800, width: "100%" }}>
                  {disabled && <LinearProgress
          color="secondary"
        />}
                    <div style={{ display: "flex", height: "100%" }}>
                      <div style={{ flexGrow: 1 }}>
                        <DataGrid
                          loading={loading}
                          rows={users}
                          columns={columns}
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          rowsPerPageOptions={[10, 20, 40]}
                          pagination
                          autoHeight
                          hideFooterSelectedRowCount
                          disableSelectionOnClick={true}
                          disableColumnSelector
                          onRowClick={(params) => loadUser(params.id)}
                        />
                      </div>
                    </div>
                  </div>
                </Container>
              </Fade>
            )}
            {userDetail && <UserInfo id={user} setUserDetail={setUserDetail} refresh={getData} />}
          </Box>
        </Grow>
      </Modal>
    </>
  );
}
