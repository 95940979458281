import React, { useState, useEffect } from "react";
import {
  Drawer,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  List,
  ListItem,
  TextField,
  InputAdornment,
  Box,
  ClickAwayListener,
  Autocomplete
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DrawerHeader from "./DrawerHeader";
import { useStyle } from "../../contexts/StyleContext";
import { ChromePicker } from "react-color";
import fonts from "../fonts";


const drawerWidth = 300;

export default function EditModule(props) {
  const [type, setType] = useState("");
  const [innerType, setInnerType] = useState("");
  const [alignment, setAlignment] = React.useState("flex-start");
  const [formats, setFormats] = React.useState(() => []);
  const [showTextColor, setShowTextColor] = useState(false)
  const [showUnderlineOptions, setShowUnderLineOptions] = useState(false)
  const { setSelectedStyle , selectedStyle} = useStyle()

  function updateComponentStyle(property, value, innerProperty, innerValue){
    props.callBack(property, value, innerProperty, innerValue)
    
    }

  useEffect(() => {
    if (selectedStyle.textAlign !== undefined) {
      let alignment
      switch (selectedStyle.textAlign) {
        case 'left':
          alignment = "flex-start"
          break;
        case 'center':
          alignment = "center"
          break;
        case 'right':
          alignment = "flex-end"
          break;
        default: alignment = "flex-start"
      }
      setAlignment(alignment)

    }
    if (selectedStyle.justifyContent !== undefined) {
      let alignment = selectedStyle.justifyContent 
  
      setAlignment(alignment)
    }
  }, [selectedStyle])
  


  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (innerType === "") {
      if (props.selected.indexOf("image") >= 0) {
    updateComponentStyle("justifyContent", newAlignment)
      }
      if (props.selected.indexOf("text") >= 0) {
        let alignment
        switch (newAlignment) {
          case 'flex-start':
            alignment = "left"
            break;
          case 'center':
            alignment = "center"
            break;
          case 'flex-end':
            alignment = "right"
            break;
          default: alignment = "left"
        }
    updateComponentStyle("textAlign", alignment)
      }
  }
        
  };

  const handleFormat = (event, newFormats) => {
    console.log(newFormats)
    switch (true) {
      case newFormats.includes("bold") && newFormats.includes("underlined") && newFormats.includes("italic") :
        updateComponentStyle("fontWeight", "bold")
        updateComponentStyle("textDecoration", "underline")
        updateComponentStyle("fontStyle", "italic")
        break;
      case newFormats.includes("bold") && newFormats.includes("underlined") :
        updateComponentStyle("fontWeight", "bold")
        updateComponentStyle("textDecoration", "underline")
        updateComponentStyle("fontStyle", "normal")
        break;
      case newFormats.includes("bold") && newFormats.includes("italic") :
          updateComponentStyle("fontWeight", "bold")
          updateComponentStyle("textDecoration", "none")
          updateComponentStyle("fontStyle", "italic")
          break;
      case newFormats.includes("underlined")  && newFormats.includes("italic") :
          updateComponentStyle("fontWeight", "normal")
          updateComponentStyle("textDecoration", "underline")
          updateComponentStyle("fontStyle", "italic")
          break;
      case newFormats.includes("bold"):
        updateComponentStyle("fontWeight", "bold")
        updateComponentStyle("textDecoration", "none")
        updateComponentStyle("fontStyle", "normal")
        break;
      case newFormats.includes("underlined"):
        updateComponentStyle("fontWeight", "normal")
        updateComponentStyle("textDecoration", "underline")
        updateComponentStyle("fontStyle", "normal")
        break;
      case newFormats.includes("italic"):
        updateComponentStyle("fontWeight", "normal")
        updateComponentStyle("textDecoration", "none")
        updateComponentStyle("fontStyle", "italic")
        break;
      case newFormats.includes("color"):
        setShowTextColor(true)
        break;
      default: 
      updateComponentStyle("fontWeight", "normal")
      updateComponentStyle("textDecoration", "none")
      updateComponentStyle("fontStyle", "normal")
      setShowTextColor(false)



    }
    if (newFormats.includes("bold")) {
      updateComponentStyle("fontWeight", "bold")
    }
    setFormats(newFormats);

   
  };

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

 async function handleFileRead(event) {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    props.handleChange(base64)
  }

  useEffect(() => {
    switch (true) {
      case props.selected.includes("text"):
        setType("text");
        //setStyle(props.style)
        break;
      case props.selected.includes("shortcode"):
        setType("shortcode");
        //setStyle(props.style)
        break;
      case props.selected.includes("column"):
        setType("column");
       // setStyle(props.style)
        break;
      case props.selected.includes("image"):
        setType("image");
        //setStyle(props.style)
        break;
      default:
        setType("page");
       // setStyle({})
    }
    if (props.innerSelected !== undefined) {
        switch (true) {
            case props.innerSelected.includes("column"):
                setInnerType('column')
                //setStyle(props.innerSelectedStyle)
            break;
            case props.innerSelected.includes("text"):
                setInnerType('text')
//setStyle(props.innerSelectedStyle)

            break;
            case props.innerSelected.includes("shortcode"):
                setInnerType('shortcode')
               // setStyle(props.innerSelectedStyle)

            break;
            case props.innerSelected.includes("image"):
                setInnerType('image')
                //setStyle(props.innerSelectedStyle)

            break;
            default: setInnerType('')
            //setStyle({})

        }
    }
  }, [props.selected, props.innerSelected]);

  const selectImage = <>
  <ListItem>
          <Typography variant="h6" gutterBottom component="label">
            Select image
          </Typography>
        </ListItem>
        <ListItem>
        <input type='file' accept="image/png, image/jpeg" onChange={(e) => handleFileRead(e)} />
        </ListItem>
  </>

  const fontSize = <>
    <ListItem>
      
          <Typography variant="h6" gutterBottom mt={2} component="label">
            Font
          </Typography>
        </ListItem>
  <ListItem>
  <Autocomplete
      disablePortal
      id="font-select"
      options={fonts}
      size="small"
      value={fonts[fonts.findIndex(item => item === selectedStyle.fontFamily)]}
      onChange={(event, newValue) => {
        updateComponentStyle("fontFamily", newValue)
      }}
      // renderOption={(props, option, state) => <ListItem className="MuiAutocomplete-option" key={props.id} sx={{fontFamily: props.key}}>{option}</ListItem>}
      sx={{ width: 300 }}
      disableClearable
      renderInput={(params) => <TextField {...params} label="Font family" />}
    />
  <TextField
          id="standard-number"
          label=""
          size="small"
          type="number"
          value={selectedStyle.fontSize}
          sx={{ width: 150 }}
          InputProps={{
            endAdornment: <InputAdornment position="end ">pt</InputAdornment>,
          }}
          onChange={(event) => {
            updateComponentStyle("fontSize", parseInt(event.target.value))
          }
        }
          variant="outlined"
        />
      </ListItem></>

  const format = <>
  <ListItem>
          <Typography variant="h6" mt={2} gutterBottom component="label">
            Style
          </Typography>
        </ListItem>
        <ListItem>
          <ToggleButtonGroup
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
            size="small"
          >
            <ToggleButton value="bold" aria-label="bold">
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton value="italic" aria-label="italic">
              <FormatItalicIcon />
            </ToggleButton>
            <ToggleButton value="underlined" aria-label="underlined">
              <FormatUnderlinedIcon />
            </ToggleButton>
            <ToggleButton value="color" aria-label="color">
              <FormatColorTextIcon sx={{color: selectedStyle.color}} />
              <ArrowDropDownIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </ListItem>
        {showTextColor &&
         <ChromePicker
                        color={selectedStyle.color}
                        // colors={colors}
                        disableAlpha={true}
                        onChange={(color) => updateComponentStyle("color", color.hex)}
                      />}
        
        </>

const textAlign = (
  <>
    <ListItem>
      <Typography variant="h6" gutterBottom component="label" mt={2}>
        Alignment
      </Typography>
    </ListItem>
    <ListItem>

      <ToggleButtonGroup
        value={alignment}
        exclusive
        size="small"
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value="flex-start" aria-label="left aligned">
          <FormatAlignLeftIcon />
        </ToggleButton>
        <ToggleButton value="center" aria-label="centered">
          <FormatAlignCenterIcon />
        </ToggleButton>
        <ToggleButton value="flex-end" aria-label="right aligned">
          <FormatAlignRightIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </ListItem>
  </>
);


        if (props.style !== undefined) {
            return (

                <Drawer
                  sx={{
                    width: drawerWidth,
                    position: "fixed",
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: drawerWidth,
                    },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={props.open}
                >
                  <DrawerHeader />
                  <IconButton onClick={props.handleDrawerClose}>
                    <ChevronRightIcon />
                  </IconButton>
                  <Divider />
            
                  <List dense>
                    {(type === 'text' || innerType === 'text') &&  <>{format} {textAlign} {fontSize}</>}
                    {(type === 'shortcode' || innerType === 'shortcode') &&  <>{format} {textAlign} {fontSize}</>}
                    {(type === 'image' || innerType === 'image') &&  <>{selectImage} {textAlign}</>}
                    {(type === 'column') &&  <></>}
            
                    
                  </List>
            {/*       {type}
                  {innerType} */}
                </Drawer>
              );
        } else {
            return null
        }
 
}
