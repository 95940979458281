import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export function Droppable(props) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    backgroundColor: isOver? 'var(--edit-color)': "transparent",
    borderRadius: "5px",
    margin: "0",
    paddingBottom: isOver? "2rem" : "1rem",
  };
  
  
  return (
    <div ref={setNodeRef} style={style} id={props.id}>
      {props.children}
    </div>
  );
}