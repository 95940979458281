import { useDraggable } from "@dnd-kit/core";


export default function SortableItem(props) {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useDraggable({ id: props.id });
  
    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0) scaleX(300%) scaleY(300%)`  : undefined,
        transition,
      position: "relative",
    };
  
    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        onClick={props.click}
      >
        {props.children}
      </div>
    );
  }