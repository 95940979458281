import React, { useContext, useState, useEffect } from "react";
import { auth, db, functions } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
  }

  export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [accessToken, setAccessToken] = useState("");
    const [firstName, setFirstnameDisplay] = useState("");
    const [tokens, setTokens] = useState("");
  
  


async function getTrialPeriod(user) {
  if (user) {
  await auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    setTokens(idTokenResult.claims.trialExpiresOn)
    //console.log(idTokenResult)
  }).catch((err) => console.log(err))}
}
  
    async function login(email, password) {
      await auth
        .signInWithEmailAndPassword(email, password)
        .then(async (e) => {
          getDisplayName()
          getTrialPeriod()
          // check if user is signed in
          if (e.user.uid !== undefined) {
            const ref = db.collection("users").doc(e.user.uid)
            let token


            // check if user connected TL before
            await ref.get().then((doc) => {
              token = doc.data().accessToken
              setFirstnameDisplay(doc.data().firstName)
            })
            if (token !== undefined) {
              // refresh access token after login
              const refresh = functions.httpsCallable("refreshTeamleader")
              await refresh({
                  uid: e.user.uid
              }).then((e) => setAccessToken(e.data) )

            }
         }
        })
        .catch((error) => {
          console.log(error);
        });
    }



  
    function logout() {
      return auth.signOut();
    }
  
    async function resetPassword(email) {
      try {
        await auth
          .sendPasswordResetEmail(email);
        // Email sent.
        console.log("Email Sent");
      } catch (error) { }
    }
  
    async function getDisplayName() {
      const ref = db.collection("users").doc(currentUser.uid)
      ref.get().then((doc) => {
        setFirstnameDisplay(doc.data().firstName)
      })
    }
  
    useEffect(() => {
      const unsubscribe = auth.onIdTokenChanged((user) => {
        console.log(user)
        setCurrentUser(user);
        getTrialPeriod(user);
        setLoading(false);
      }
      );

      console.log('called')
  
      return () => {unsubscribe()};
    }, []);
  
    const value = {
      currentUser,
      accessToken,
      firstName,
      tokens,
      setFirstnameDisplay,
      login,
      logout,
      resetPassword,
    };
  
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    );
  }